
import { 
    FETCH_LOGS_STARTED, FETCH_LOGS_FAILED, FETCH_LOGS_SUCCESS,
    FETCH_LOG_STARTED, FETCH_LOG_FAILED, FETCH_LOG_SUCCESS,
    DELETE_LOGS_STARTED, DELETE_LOGS_FAILED, DELETE_LOGS_SUCCESS,
    CLEAR_EDIT, SHOW_LOG_FORM, HIDE_LOG_FORM } from '../actions/logs';
import moment from 'moment';

/**
 * Logs initialstate
 */
const initialState = {
    loading:false,
    editid:0,
    logs: [],
    error:'',
    showform:false
};

/**
 * Logs reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function logs(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOG_FORM:
        return {
            ...state,
            showform:true,
            editid:action.payload.id
        };
    
    case HIDE_LOG_FORM:
        return {
            ...state,
            showform:false
        };
    
    case CLEAR_EDIT:
        return {
            ...state,
            editid:0
        };
    
    case FETCH_LOGS_STARTED:
        return {
            ...state,
            loading:true
        };
    case FETCH_LOGS_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case FETCH_LOGS_SUCCESS:
        return {
            ...state,
            loading:false,
            logs:action.payload.logs
        };
    case FETCH_LOG_STARTED:
        return {
            ...state,
            loading:true,
            editid:action.payload.id
        };
    case FETCH_LOG_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error,
            editid:0
        };
    case FETCH_LOG_SUCCESS:
      return {
            ...state,
            loading:false,
            editid:action.payload.id,
            //Update current if found, (or add to array if not found (this should not happen))
            logs:state.logs.find(i => i.Id === action.payload.id) ? 
                    state.logs.map((item) => item.Id === action.payload.id ? action.payload.log : item) : 
                    [...state.logs,action.payload.log]
      };
    
    case DELETE_LOGS_STARTED:
        return {
            ...state,
            loading:true
        };
      case DELETE_LOGS_FAILED:
          return {
              ...state,
              loading:false,
              error:action.payload.error
          };
      case DELETE_LOGS_SUCCESS:
          return {
              ...state,
              loading:false,
              logs: [...state.logs].filter(i => moment(i.Created).isAfter(moment(action.payload.query.queryparameters.date))),
              editid:0
          };
    default:
      return state;
  }
}

/***
 * Selector to get by id 
 */
export function getLog(id, logs) { 

    if(id === 0|| !(logs && logs.find))
        return null;
     
    let item = logs.find(i => i.Id === id);
    
    if(item)
        return item;
    else 
        return null;
}