import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header/Header';
import MenuItem from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import resources from '../../../resources';
import configuration from '../../../configuration';
import OrderHeaderSearch from './OrderHeaderSearch';
import OrderHeaderTable from '../components/OrderHeaderTable';
import { CSVLink } from "react-csv";
import { int32 } from '../../../common/int32';
import { getOrderHeader } from '../reducers/orderheader';
import { fetchOrderHeaders, fetchOrderHeader, createOrderHeader, updateOrderHeader, clearEdit, showOrderHeaderForm, hideOrderHeaderForm, deleteOrderHeader, selectOrderHeader, clearSelectedOrderHeader } from '../actions/orderheader';
import { Input } from 'semantic-ui-react';
import OrderHeaderForm from '../components/OrderHeaderForm';
import OrderCsvParser from '../common/OrderCsvParser';
import { showToast } from '../../../toast/actions/toast';
import { getImportSettings } from '../reducers/setting';

class OrderHeaderManagement extends Component {
  constructor(props) {
      super(props); 
      this.state = {
          search: '',
          sortcolumn: '',
          sortorder: '',
          loadingCsv:false,
          csvData:null,
          csvHeaders:null,
          parsing: false
      }; 
      
      this.fileRef = React.createRef();
      this.csvDownloadRef = React.createRef();
  }
  componentDidMount(){
    this.loadData();
  }
  sortby = (col, order) => {
    this.setState({ sortcolumn: col, sortorder: order },this.loadData);
  }
  /**
   * Create query for data
   */
  constructQuery = (start = 0, rows) => {
    if(rows === undefined)
        rows = configuration.paging.pageSize;

    let { search, sortcolumn, sortorder} = this.state;
    let query = { urlparameters:{startfromRow:start, rowCount:rows}, queryparameters:{} };
    
    if(search && search !== '')
        query = {...query, queryparameters:{...query.queryparameters, searchword:search}};
    
    if(sortcolumn !== '')
        query = {...query, queryparameters:{...query.queryparameters, sortcolumn:sortcolumn}};
    if(sortorder !== '')
        query = {...query, queryparameters:{...query.queryparameters, sortorder:sortorder}};

    return query;
  }

  loadData = (start = 0, rows) =>{
    let query = this.constructQuery(start,rows);
    this.props.fetch(query);
  }
  search = (searchword) => this.setState({search:searchword}, this.loadData);
   /***
   * Create csv from resultset,
   * - Load all data in database for this query
   * - Conver headers for csv from current table in view
   * - Convert table data rows for csv from current table in view
   */
  getCsv = (e) => {
    e.preventDefault();
    const {loadingCsv} = this.state;
    const {toast} = this.props;
    if(loadingCsv) return;

    let query = this.constructQuery(0, int32.MAX);
    this.setState({ loadingCsv: true }, async () => {
        try{
            //Get data and headers
            let data = await new OrderCsvParser().getHeadersCsv(query)
            
            //Set to state and simulate click, and then clear state 
            this.setState({ csvData: data.data, csvHeaders:data.headers }, () => {
                if (this.csvDownloadRef && this.csvDownloadRef.current && this.csvDownloadRef.current.link)
                    this.csvDownloadRef.current.link.click();

                this.setState({ csvData: null, csvHeaders:null, loadingCsv: false });
            });
                
        }
        catch(error){
            this.setState({ csvData: null, csvHeaders:null, loadingCsv: false }, () => {
                toast(resources.TO_CSV, resources.ERROR + ': ' + error.message ? error.message : error, 'red');
            });
        }
    });
  }
  /**
     * Read selected file, import csv
     * @param {*} e 
     */
    importCsv = (e) => {
        e.preventDefault();
        const { toast,create,username,importsettings } = this.props;
        const { parsing } = this.state;
        if(parsing)
            return;
        
            console.log(importsettings);
        if (this.fileRef.current && this.fileRef.current.files && this.fileRef.current.files.length > 0) {

            if (this.fileRef.current.value.toLowerCase().endsWith('csv') === false) {
                this.setState({ parsing:false}, () => toast(resources.FAILED, resources.ONLY_CSV_FILE, 'red'));
            }
            else {
                let file = this.fileRef.current.files[0];
                this.setState({parsing:true}, () => {
                    
                    try{
                        new OrderCsvParser().parseCsv(username, importsettings, file, 
                            (data) => {
                                //Success 
                                this.setState({parsing:false}, () => create(data));
                            }, (error) => {
                                this.setState({parsing:false}, () => toast(resources.ERROR, error.toString(), 'red'));
                            });
                    }
                    catch(error){
                        this.setState({parsing:false}, () => toast(resources.ERROR, (error.message ? error.message : error).toString(), 'red'));
                    }
                    
                });
            }
        }
        
        
    }
  getbyid = (id) =>{
        this.props.showform();
        this.props.fetchItem(id);
  }
  selectbyid = (id) =>{
    this.props.select(id);
    this.props.fetchItem(id);
  }
  remove = (id) =>{
      if(window.confirm(resources.ARE_YOU_SURE))
          this.props.remove(id);
  }
  hideform = () =>{
    this.props.hideform();
    this.props.clear();
 }
  render() {
    const { loadingCsv,csvData,csvHeaders, search, parsing } = this.state;
    const { items, loading, showform, show, edititem,username } = this.props;
   
    return (
      <React.Fragment>
        <OrderHeaderSearch search={this.search} searchword={search} 
                right={<MenuItem>
                            <Input type='file' action>
                                <input style={{minHeight:'2.9em'}} ref={this.fileRef} />
                                <Button type='button' basic loading={parsing || loading} onClick={this.importCsv}><Icon name='upload' /> {resources.IMPORT_CSV}</Button>
                            </Input>
                            &nbsp;
                            <OrderHeaderForm text={resources.CREATE_NEW} 
                                        size='medium' hideform={this.hideform} showform={showform}
                                        edititem={edititem} loading={loading} show={show} user={username}
                                        create={this.props.create} update={this.props.update} clear={this.props.clear} />

                            &nbsp;
                            <CSVLink ref={this.csvDownloadRef} 
                                        headers={csvHeaders} 
                                        filename={resources.ORDERHEADER_TITLE + '.csv'} data={csvData === null ? [] : csvData} 
                                        separator={";"}></CSVLink>
                            <Button basic style={{whiteSpace:'nowrap',minHeight:'2.9em'}} onClick={this.getCsv}><Icon name={loadingCsv ? 'spinner' : 'file excel outline'} loading={loadingCsv} /> {resources.TO_CSV}</Button>
                        </MenuItem>
                        }>
            <MenuItem>
                <Header as='h3' className='finnid-header'>
                {resources.ORDERHEADER_TITLE}
                </Header>
            </MenuItem>
        </OrderHeaderSearch>
        <OrderHeaderTable
          loading={loading}
          data={items}
          sortby={this.sortby}
          pageSize={configuration.paging.pageSize}
          loadData={this.loadData}
          mobilecolumns={2}
          tabletcolumns={8}
          remove={this.remove}
          getbyid={this.getbyid}
          select={this.selectbyid}
         />
      </React.Fragment>
    );
  }
}

OrderHeaderManagement.propTypes = {
    username: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchItem: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    showform: PropTypes.func.isRequired,
    hideform: PropTypes.func.isRequired,
    select: PropTypes.func.isRequired,
    clearSelected: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    edititem: PropTypes.object,
    importsettings: PropTypes.object,
    show: PropTypes.bool.isRequired,
    toast: PropTypes.func.isRequired
};
const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        fetch: (query) => dispatch(fetchOrderHeaders(query)),
        fetchItem: (id) => dispatch(fetchOrderHeader(id)),
        select: (id) => dispatch(selectOrderHeader(id)),
        clearSelected: (id) => dispatch(clearSelectedOrderHeader(id)),
        create: (item) => dispatch(createOrderHeader(item)),
        update: (id,item) => dispatch(updateOrderHeader(id, item)),
        clear: () => dispatch(clearEdit()),
        showform: () => dispatch(showOrderHeaderForm()),
        hideform: () => dispatch(hideOrderHeaderForm()),
        remove: (id) => dispatch(deleteOrderHeader(id)),
        toast: (title,msg,color) => dispatch(showToast(title,msg,color))
    }

}
function mapStateToProps(state) {
    let edititem = getOrderHeader(state.orderheader.editid, state.orderheader.orderheaders);
    let importsettings = getImportSettings(state.setting.settings);
    return { 
        username: state.login.user ? state.login.user.UserName : '',
        items: state.orderheader.orderheaders,
        loading: state.orderheader.loading,
        show: state.orderheader.showform,
        importsettings,
        edititem
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderHeaderManagement); 