import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import { logout } from '../pages/login/actions/login';
import resources from './../resources';
import configuration from './../configuration';
import MainMenu from './MainMenu';
import DynamicMenu from './DynamicMenu';
import { Link } from 'react-router-dom'

class MenuSwitcher extends Component {

    
    logout = () => {
        if(this.props.loading === false)
            this.props.logout();
    }
    render() {
        const { history, user, pathname, loading } = this.props;
        return (
            (pathname === configuration.routes.login.route ? (null) : (
                (configuration.DYNAMIC_MENU_URL && configuration.DYNAMIC_MENU_URL !== "") ? 
                (<DynamicMenu user={user} link={Link}
                        lang={configuration.lang.substring(0,2)} 
                        baseurl={configuration.DYNAMIC_MENU_URL}>
                        <Menu.Item position='right' active={pathname === configuration.routes.login.route} onClick={this.logout}>
                            <Icon name='sign out' /> <div className='mobile hidden'>{loading ? (<Loader active size='mini' />):(resources.LOGOUT)}</div>
                        </Menu.Item>
                </DynamicMenu>):
                (<MainMenu history={history} />) ))
        );
    }
}
MenuSwitcher.propTypes = {
    logout: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool
  };
  const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    }
  }
  function mapStateToProps(state) {
    return { 
        pathname: state.router.location.pathname,
        user: state.login.user,
        loading: state.login.loading
      };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(MenuSwitcher);
