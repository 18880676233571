import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header/Header';
import MenuItem from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import resources from '../../resources';
import LogTable  from './components/LogTable';
import LogSearch from './components/LogSearch';
import configuration from '../../configuration';
import LogForm from './components/LogForm';
import { fetchLogs, deleteLogs, clearLogEdit, showLogForm, hideLogForm } from './actions/logs';
import { getLog } from './reducers/logs';

class Logs extends Component {
  constructor(props) {
      super(props); 
      this.state = {
          search: '',
          sortcolumn: '',
          sortorder: ''
      }; 
  }
  componentDidMount(){
    this.loadData();
  }
  sortby = (col, order) => {
    this.setState({ sortcolumn: col, sortorder: order },this.loadData);
  }
  loadData = (start = 0, rows) => {
    if(rows === undefined)
      rows = configuration.paging.pageSize;

    let { search, sortcolumn, sortorder } = this.state;
    let query = { urlparameters:{}, queryparameters:{startfromRow:start, rowCount:rows} };
    
    if(search !== '')
      query = {...query, queryparameters:{...query.queryparameters, searchWord:search}};
    if(sortcolumn !== '')
      query = {...query, queryparameters:{...query.queryparameters, sortcolumn:sortcolumn}};
    if(sortorder !== '')
      query = {...query, queryparameters:{...query.queryparameters, sortOrder:sortorder}};

    this.props.dispatch(fetchLogs(query));
  }
  search = (searchword,todate) => this.setState({search:searchword,todate:todate}, this.loadData);
  clear = () => this.props.dispatch(clearLogEdit());
  openform = () => this.props.dispatch(showLogForm());
  deletelogs = (todate) => {
      this.props.dispatch(deleteLogs({urlparameters:{},queryparameters:{ date: todate }}));
  }
  getbyid = (id) =>{
    this.openform();
    this.props.dispatch(showLogForm(id));
  }
  hideform = () =>{
    this.props.dispatch(hideLogForm());
    this.clear();
  }
  
  render() {
    const { logs, loadingLogs, editlog, showform } = this.props;

    return (
      <React.Fragment>
        <LogSearch search={this.search} deletelogs={this.deletelogs} create={<MenuItem><LogForm text={resources.CREATE_NEW} 
                                                                                            size='large' hideform={this.hideform} showform={this.openform}
                                                                                            editlog={editlog} loading={loadingLogs} show={showform} 
                                                                                            clear={this.clear} /></MenuItem>}>
        <MenuItem>
            <Header as='h3' className='finnid-header'>
              {resources.LOGS_TITLE}
            </Header>
          </MenuItem>
        </LogSearch>
        <LogTable
          loading={loadingLogs}
          data={logs}
          sortby={this.sortby}
          pageSize={configuration.paging.pageSize}
          loadData={this.loadData}
          mobilecolumns={2}
          tabletcolumns={8}
          getbyid={this.getbyid}
         />
      </React.Fragment>
    );
  }
}

Logs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  loadingLogs: PropTypes.bool.isRequired,
  editlog: PropTypes.object,
  showform: PropTypes.bool
};

function mapStateToProps(state) {
  
  let editlog = getLog(state.logs.editid, state.logs.logs);
  return { 
      logs: state.logs.logs, 
      loadingLogs: state.logs.loading,
      showform: state.logs.showform,
      editlog
    };
}

export default connect(mapStateToProps)(Logs);
