import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'semantic-ui-react/dist/commonjs/addons/Pagination';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

class BasePaging extends Component {
  constructor(props) {
    super(props);
    this.componentKey = this.props.parentkey === undefined ? 'basepaging' : this.props.parentkey;
    this.state = { 
      [this.componentKey + 'start']: 0, 
      [this.componentKey + 'rows']: this.props.rows ? this.props.rows : 15 };
      
    this.first = this.first.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  valid() {
    return true;
  }
  first() {
    this.setState({ [this.componentKey + 'start']: 0 }, () => {
      this.props.loadEntries(0, this.state[this.componentKey + 'rows']);
    });
  }
  prev() {
    if (this.state[this.componentKey + 'start'] !== 0) {
      let newStart = this.state[this.componentKey + 'start'] - this.state[this.componentKey + 'rows'];
      if (newStart < 0)
        newStart = 0;
      this.setState({ [this.componentKey + 'start']: newStart }, () => {
        this.props.loadEntries(newStart, this.state[this.componentKey + 'rows']);
      });
    }
  }
  next() {
    let newStart = this.state[this.componentKey + 'start'] + this.state[this.componentKey + 'rows'];
    this.setState({ [this.componentKey + 'start']: newStart }, () => {
      this.props.loadEntries(newStart, this.state[this.componentKey + 'rows']);
    });
  }

  render() {
    let prevStart = this.state[this.componentKey + 'start'] - this.state[this.componentKey + 'rows'] < 0 ? 0 : this.state[this.componentKey + 'start'] - this.state[this.componentKey + 'rows'];
    let prevEnd = prevStart + this.state[this.componentKey + 'rows'];
    let nextStart = this.state[this.componentKey + 'start'] + this.state[this.componentKey + 'rows'];
    let nextEnd = nextStart + this.state[this.componentKey + 'rows'];

    return (
      
      <Pagination
        key={this.componentKey + '-pagination'}
        defaultActivePage={0}
        ellipsisItem={null}
        firstItem={{ onClick:() => this.first(), content: <div> <Icon name='angle double left' /></div>, icon: true }}
        prevItem={{ onClick:() => this.prev(), content: <div><Icon name='angle left' /> {this.state[this.componentKey + 'start'] > 0 ? prevStart + ' - ' + prevEnd : ''} </div>, icon: true }}
        nextItem={{  content: this.state[this.componentKey + 'start'] + '-' + nextStart, icon: false }}
        lastItem={{ onClick:() => this.next(), content: <div> {nextStart} - {nextEnd} <Icon name='angle right' /></div>, icon: true }}
        totalPages={0}
      />

    )
  }
}

BasePaging.propTypes = {
  loadEntries: PropTypes.func,
  rows: PropTypes.number,
  parentkey: PropTypes.string
}
export default BasePaging;