
const configuration = {
    lang: process.env.REACT_APP_LANG,
    BASE_PATH:process.env.REACT_APP_BASE_PATH,
    AUTH_URL: process.env.REACT_APP_AUTH_URL,
    ROOT_URL: process.env.REACT_APP_ROOT_URL,
    DYNAMIC_MENU_URL: process.env.REACT_APP_DYNAMIC_MENU_URL,
    localStorageKey: 'attune-token',
    ALLOWED_ROLES: ["Admin","SuperUser","User"], //Only these roles are allowed to access web
    routes:{
        login:{
            route:process.env.REACT_APP_BASE_PATH + (process.env.REACT_APP_BASE_PATH.endsWith("/") ? "login" : "/login"), 
            roles:["Admin","SuperUser","User"]
        },
        orders:{
            route:process.env.REACT_APP_BASE_PATH + (process.env.REACT_APP_BASE_PATH.endsWith("/") ? "" : "/"), 
            roles:["Admin","SuperUser","User"]
        },
        logs:{
            route:process.env.REACT_APP_BASE_PATH + (process.env.REACT_APP_BASE_PATH.endsWith("/") ? "logs" : "/logs"), 
            roles:["Admin"]
        }
    },
    paging: {
        pageSize:15
    }
};
/* if freeze is supported */
if(typeof Object !== "undefined" && !!Object.freeze){
    Object.freeze(configuration);
}

export default configuration;