

import { 
    FETCH_ORDERROWS_STARTED, FETCH_ORDERROWS_FAILED, FETCH_ORDERROWS_SUCCESS,
    FETCH_ORDERROW_STARTED, FETCH_ORDERROW_FAILED, FETCH_ORDERROW_SUCCESS,
    CREATE_ORDERROW_STARTED,CREATE_ORDERROW_SUCCESS,CREATE_ORDERROW_FAILED,
    UPDATE_ORDERROW_STARTED,UPDATE_ORDERROW_SUCCESS,UPDATE_ORDERROW_FAILED,
    UPDATE_ORDERROW_STATE_STARTED,UPDATE_ORDERROW_STATE_SUCCESS,UPDATE_ORDERROW_STATE_FAILED,
    DELETE_ORDERROW_STARTED, DELETE_ORDERROW_FAILED, DELETE_ORDERROW_SUCCESS,
    CLEAR_ORDERROW_EDIT, SHOW_ORDERROW_FORM, HIDE_ORDERROW_FORM } from '../actions/orderrow';

    

/**
 *  initialstate
 */
const initialState = {
    loading:false,
    editid:0,
    orderrows: [],
    error:'',
    showform:false
};

/**
 *  reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function orderrow(state = initialState, action) {
  switch (action.type) {
    case SHOW_ORDERROW_FORM:
        return {
            ...state,
            showform:true
        };
    
    case HIDE_ORDERROW_FORM:
        return {
            ...state,
            showform:false
        };
    
    case CLEAR_ORDERROW_EDIT:
        return {
            ...state,
            editid:0
        };
    
    case FETCH_ORDERROWS_STARTED:
        return {
            ...state,
            loading:true
        };
    case FETCH_ORDERROWS_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case FETCH_ORDERROWS_SUCCESS:
        return {
            ...state,
            loading:false,
            orderrows:action.payload.orderrows
        };
    case FETCH_ORDERROW_STARTED:
        return {
            ...state,
            loading:true,
            editid:action.payload.id
        };
    case FETCH_ORDERROW_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error,
            editid:0
        };
    case FETCH_ORDERROW_SUCCESS:
      return {
            ...state,
            loading:false,
            editid:action.payload.id,
            //Update current if found, (or add to array if not found (this should not happen))
            orderrows:state.orderrows.find(i => i.id === action.payload.id) ? 
                    state.orderrows.map((item) => item.id === action.payload.id ? action.payload.orderrow : item) : 
                    [...state.orderrows,action.payload.orderrow]
      };
    case CREATE_ORDERROW_STARTED:
      return {
          ...state,
          loading:true
      };
    case CREATE_ORDERROW_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case CREATE_ORDERROW_SUCCESS:
        return {
            ...state,
            loading:false,
            orderrows:[...state.orderrows,action.payload.orderrow],
            editid:0
        };


    case UPDATE_ORDERROW_STARTED:
        return {
            ...state,
            loading:true
        };
    case UPDATE_ORDERROW_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case UPDATE_ORDERROW_SUCCESS:
        return {
            ...state,
            loading:false,
            //Update array, (or add to array if not found (this should not happen))
            orderrows:state.orderrows.find(i => i.id === action.payload.id) ? 
                    state.orderrows.map((item) => item.id === action.payload.id ? action.payload.orderrow : item) : 
                    [...state.orderrows,action.payload.orderrow],
            editid:0
        };


    case UPDATE_ORDERROW_STATE_STARTED:
        return {
            ...state,
            loading:true
        };
    case UPDATE_ORDERROW_STATE_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case UPDATE_ORDERROW_STATE_SUCCESS:
        return {
            ...state,
            loading:false,
            //Update array, (or add to array if not found (this should not happen))
            orderrows:state.orderrows.find(i => i.id === action.payload.id) ? 
                    state.orderrows.map((item) => item.id === action.payload.id ? {...item, orderStatus:action.payload.orderStatus} : item) : 
                    [...state.orderrows],
            editid:0
        };



    case DELETE_ORDERROW_STARTED:
        return {
            ...state,
            loading:true
        };
      case DELETE_ORDERROW_FAILED:
          return {
              ...state,
              loading:false,
              error:action.payload.error
          };
      case DELETE_ORDERROW_SUCCESS:
  
          return {
              ...state,
              loading:false,
              orderrows: [...state.orderrows].filter(i => i.id !== action.payload.id),
              editid:0
          };
    default:
      return state;
  }
}

/***
 * Selector to get by id 
 */
export function getOrderRow(id, orderrows) { 

    if(id === 0|| !(orderrows && orderrows.find))
        return null;
     
    let item = orderrows.find(i => i.id === id);
    
    if(item)
        return item;
    else 
        return null;
}


/***
 * Selector to search from list of rows
 */
export function filterOrderRows(search, orderrows, sortcolumn, sortorder) { 

    if(!(orderrows && orderrows.filter))
        return orderrows;


    let items = search === '' ? [...orderrows] : orderrows.filter(i => i.productCode.toUpperCase().includes(search.toUpperCase()) ||
                                                            i.productName.toUpperCase().includes(search.toUpperCase()) 
                                                        );
    
    if(sortcolumn && sortcolumn !== ''){

        items.sort((a, b) => {
            let sortA = a[sortcolumn] ? (window.isNaN(a[sortcolumn]) ? a[sortcolumn].toUpperCase() : a[sortcolumn]) : '';
            let sortB = b[sortcolumn] ? (window.isNaN(b[sortcolumn]) ? b[sortcolumn].toUpperCase() : b[sortcolumn]) : ''; 


            if (sortA < sortB) {
                return sortorder === "asc" ? -1 : 1;
            }
            if (sortA > sortB) {
                return sortorder === "asc" ? 1 : -1;
            }

            // names must be equal
            return 0;
          });
    }

    if(items)
        return items;
    else 
        return null;
}