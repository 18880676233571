import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseTable from '../../../components/BaseTable';
import resources from '../../../resources';
import moment from 'moment';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import { getOrderStateText, OrderState } from '../common/OrderState';

class OrderHeaderTable extends Component { 
    
    
    render() {
        const { loading, data, sortby, pageSize, mobilecolumns, tabletcolumns, loadData, getbyid, select, remove } = this.props;
        return (<BaseTable 
                    loading={loading}
                    headers={constructOrderHeaderTableHeaders()}
                    rows={constructOrderHeaderTableRows(data, getbyid, select, remove)}
                    sortby={sortby}
                    pageSize={pageSize}
                    mobilecolumns={mobilecolumns}
                    tabletcolumns={tabletcolumns}
                    loadEntries={loadData}
                />);
    }
}
OrderHeaderTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    sortby: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    mobilecolumns: PropTypes.number, //not required
    tabletcolumns: PropTypes.number, //not required
    getbyid: PropTypes.func,
    remove: PropTypes.func,
    select: PropTypes.func
}

export default OrderHeaderTable;


/*
{
        this.id = id;
        this.createdBy = createdby;
        this.orderListNumer = orderlistnumber;
        this.created = created === '' ? moment().format('YYYY-MM-DD') : created;
        this.modified = modified === '' ? moment().format('YYYY-MM-DD') : modified;
        this.costPlace = costplace;
        this.room = room;
        this.state = state;
}
*/

export const constructOrderHeaderTableHeaders = () => {
    return [
        { value: resources.ORDERLIST_NUMBER, name: 'Id', width:2 },
        { value: resources.COST_PLACE, name: 'CostPlace' },
        { value: resources.ROOM, name: 'Room' },
        { value: resources.CREATED_BY, name: 'CreatedBy' },
        { value: resources.CREATED, name: 'Created' },
        { value: resources.MODIFIED_BY, name: 'ModifiedBy' },
        { value: resources.UPDATED, name: 'Modified' },
        { value: resources.STATE, name: 'OrderStatus' },
        { value: '', width: 1, name: '', style:{width:'55px'} }
    ];
}

/*
{
    "id": 0,
    "createdBy": "string",
    "modifiedBy": "string",
    "created": "2019-10-31T08:55:16.275Z",
    "modified": "2019-10-31T08:55:16.275Z",
    "costPlace": "string",
    "room": "string",
    "orderStatus": "None",
    "rows": [ ...OrderRows... ]
}
*/
export const constructOrderHeaderTableRows = (data, getbyid, select, remove) => {
 
    return data.map((item, index) => {
        return {
            cells: [{
                        value:item.id,
                    },{
                        value:item.costPlace
                    },{
                        value:item.room
                    },{
                        value:item.createdBy
                    },{
                        value:item.created ? moment(item.created).local().format('DD.MM.YYYY HH:mm:ss') : ''
                    },{
                        value:item.modifiedBy
                    },{
                        value:item.modified ? moment(item.modified).local().format('DD.MM.YYYY HH:mm:ss') : ''
                    },{
                        value:getOrderStateText(item.orderStatus),
                        negative:item.orderStatus === OrderState.Rejected,
                        warning:item.orderStatus === OrderState.InProgress,
                        positive:item.orderStatus === OrderState.Accepted
                    },{
                        custom:<React.Fragment><Popup trigger={<Button icon size='mini' onClick={() => select(item.id)} primary>
                                                    <Icon name='folder open outline' />
                                                </Button>} content={resources.OPEN} /><Popup trigger={<Button icon size='mini' onClick={() => getbyid(item.id)} basic primary>
                                                        <Icon name='edit' />
                                                    </Button>} content={resources.EDIT} /><Popup trigger={<Button icon size='mini' onClick={() => remove(item.id)} basic color='red'>
                                                        <Icon name='remove' />
                                                    </Button>} content={resources.DELETE} /></React.Fragment>,
                        style:{whiteSpace:'nowrap'}
                    }]
        };
    });
};