

import { 
    FETCH_ORDERHEADERS_STARTED, FETCH_ORDERHEADERS_FAILED, FETCH_ORDERHEADERS_SUCCESS,
    FETCH_ORDERHEADER_STARTED, FETCH_ORDERHEADER_FAILED, FETCH_ORDERHEADER_SUCCESS,
    CREATE_ORDERHEADER_STARTED,CREATE_ORDERHEADER_SUCCESS,CREATE_ORDERHEADER_FAILED,
    UPDATE_ORDERHEADER_STARTED,UPDATE_ORDERHEADER_SUCCESS,UPDATE_ORDERHEADER_FAILED,
    UPDATE_ORDERHEADER_STATE_STARTED,UPDATE_ORDERHEADER_STATE_SUCCESS,UPDATE_ORDERHEADER_STATE_FAILED,
    DELETE_ORDERHEADER_STARTED, DELETE_ORDERHEADER_FAILED, DELETE_ORDERHEADER_SUCCESS,
    CLEAR_ORDERHEADER_EDIT, SHOW_ORDERHEADER_FORM, HIDE_ORDERHEADER_FORM,
    PATCH_ORDERHEADER_STARTED, PATCH_ORDERHEADER_SUCCESS, PATCH_ORDERHEADER_FAILED,
    SELECT_ORDERHEADER_SELECT,CLEAR_ORDERHEADER_SELECT  } from '../actions/orderheader';

/**
 *  initialstate
 */
const initialState = {
    loading:false,
    editid:0,
    selectedid:0,
    orderheaders: [],
    error:'',
    showform:false
};

/**
 *  reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function orderheader(state = initialState, action) {
  switch (action.type) {
    case SHOW_ORDERHEADER_FORM:
        return {
            ...state,
            showform:true
        };
    
    case HIDE_ORDERHEADER_FORM:
        return {
            ...state,
            showform:false
        };
    
    case CLEAR_ORDERHEADER_EDIT:
        return {
            ...state,
            editid:0
        };
        
    case SELECT_ORDERHEADER_SELECT:
        return {
            ...state,
            selectedid:action.payload.id
        };
    case CLEAR_ORDERHEADER_SELECT:
            return {
                ...state,
                selectedid:0
            };

    case FETCH_ORDERHEADERS_STARTED:
        return {
            ...state,
            loading:true
        };
    case FETCH_ORDERHEADERS_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case FETCH_ORDERHEADERS_SUCCESS:
        return {
            ...state,
            loading:false,
            orderheaders:action.payload.orderheaders
        };
    case FETCH_ORDERHEADER_STARTED:
        return {
            ...state,
            loading:true,
            editid:action.payload.id
        };
    case FETCH_ORDERHEADER_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error,
            editid:0
        };
    case FETCH_ORDERHEADER_SUCCESS:
      return {
            ...state,
            loading:false,
            editid:action.payload.id,
            //Update current if found, (or add to array if not found (this should not happen))
            orderheaders:state.orderheaders.find(i => i.id === action.payload.id) ? 
                    state.orderheaders.map((item) => item.id === action.payload.id ? action.payload.orderheader : item) : 
                    [...state.orderheaders,action.payload.orderheader]
      };
    case CREATE_ORDERHEADER_STARTED:
      return {
          ...state,
          loading:true
      };
    case CREATE_ORDERHEADER_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case CREATE_ORDERHEADER_SUCCESS:
        return {
            ...state,
            loading:false,
            orderheaders:[...state.orderheaders,action.payload.orderheader],
            editid:0
        };


    case UPDATE_ORDERHEADER_STARTED:
        return {
            ...state,
            loading:true
        };
    case UPDATE_ORDERHEADER_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case UPDATE_ORDERHEADER_SUCCESS:
        return {
            ...state,
            loading:false,
            //Update array, (or add to array if not found (this should not happen))
            orderheaders:state.orderheaders.find(i => i.id === action.payload.id) ? 
                    state.orderheaders.map((item) => item.id === action.payload.id ? action.payload.orderheader : item) : 
                    [...state.orderheaders,action.payload.orderheader],
            editid:0
        };


    case UPDATE_ORDERHEADER_STATE_STARTED:
        return {
            ...state,
            loading:true
        };
    case UPDATE_ORDERHEADER_STATE_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case UPDATE_ORDERHEADER_STATE_SUCCESS:
        return {
            ...state,
            loading:false,
            //Update array, (or add to array if not found (this should not happen))
            orderheaders:state.orderheaders.find(i => i.id === action.payload.id) ? 
                    state.orderheaders.map((item) => item.id === action.payload.id ? {...item, orderStatus:action.payload.orderStatus, rows:item.rows ? item.rows.map(row => {return {...row, orderStatus:action.payload.orderStatus};} ) : item.rows } : item) : 
                    [...state.orderheaders],
            editid:0
        };


    case PATCH_ORDERHEADER_STARTED:
        return {
            ...state,
            loading:true
        };
    case PATCH_ORDERHEADER_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case PATCH_ORDERHEADER_SUCCESS:
        return {
            ...state,
            loading:false,
            //Update array, (or add to array if not found (this should not happen))
            orderheaders:state.orderheaders.find(i => i.id === action.payload.id) ? 
                    state.orderheaders.map((item) => {
                        if(item.id === action.payload.id) 
                        {
                            //Update everything by Rows property, 
                            let updateItem = {...action.payload.orderheader, rows:item.rows};
                            return updateItem;
                        }else {
                            return item;
                        }
                    }) : 
                    [...state.orderheaders,action.payload.orderheader],
            editid:0
        };


    case DELETE_ORDERHEADER_STARTED:
        return {
            ...state,
            loading:true
        };
      case DELETE_ORDERHEADER_FAILED:
          return {
              ...state,
              loading:false,
              error:action.payload.error
          };
      case DELETE_ORDERHEADER_SUCCESS:
  
          return {
              ...state,
              loading:false,
              orderheaders: [...state.orderheaders].filter(i => i.id !== action.payload.id),
              editid:0
          };
    default:
      return state;
  }
}

/***
 * Selector to get by id 
 */
export function getOrderHeader(id, orderheaders) { 

    if(id === 0|| !(orderheaders && orderheaders.find))
        return null;
     
    let item = orderheaders.find(i => i.id === id);
    
    if(item)
        return item;
    else 
        return null;
}