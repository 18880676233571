
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

/**
 * Show toast message with details
 * @param {Toast title} title 
 * @param {Toast message} message 
 * @param {color = red, orange, yellow, olive, green, teal, blue, violet, purple, pink, brown, grey, black} color 
 */
export function showToast(title,message,color) {
    return { type: SHOW_TOAST, payload:  {title,message,color}  }
}
/***
 * Hide and clear toast message
 */
export function hideToast() {
    return { type: HIDE_TOAST }
}