import React, { Component } from 'react';
import PropTypes from 'prop-types';
import resources from '../../../resources';
import Menu  from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

class LogSearch extends Component {
    constructor(props){
        super(props);
        this.dateRef = React.createRef();
    }
    search(e){
        let searchword = e.target.value;
        const { search } = this.props;
        if(this.searchTimeout && this.searchTimeout !== null){
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
        this.searchTimeout = setTimeout(() => {
            search(searchword);
        }, 1000);
    }

    render() { 
        const { children, create, deletelogs } = this.props;
        return (<Menu secondary stackable>
               {children}
               <Menu.Menu position='left'>
                    <Menu.Item>
                        <Input type='date' action ref={this.dateRef}>
                            <input />
                            <Button color='google plus' style={{ whiteSpace: 'nowrap' }} onClick={(e) => {e.preventDefault(); deletelogs(this.dateRef.current.inputRef.current.value);}}>
                                <Icon name='trash' /> {resources.DELETE_OLD}
                            </Button>
                        </Input>
                            
                    </Menu.Item>
                </Menu.Menu>
                <Menu.Menu position='right'>
                    {create}
                    <Menu.Item>
                        <Input icon='search' placeholder={resources.SEARCH + '...'} onChange={(e) => this.search(e)} />
                    </Menu.Item>
                </Menu.Menu>
                </Menu>);
    } 
}
LogSearch.propTypes = {
    children: PropTypes.any,//not required
    search: PropTypes.func.isRequired,
    deletelogs: PropTypes.func.isRequired
}
  
export default LogSearch;