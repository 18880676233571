import resources from "../../../resources";

export const OrderState = {
    None:0,
    Created: 1,
    InProgress:2,
    Done:3,
    Accepted:4,
    Rejected:5
}
/**
 * Translate orderstate to text
 * @param {*} orderstate 
 */
export const getOrderStateText = (orderstate) => {
    
    switch(orderstate){
        case OrderState.None:
            return '-';//resources.NONE;
        case OrderState.Created:
            return resources.CREATED;
        case OrderState.InProgress:
            return resources.IN_PROGRESS;
        case OrderState.Done:
            return resources.DONE;
        case OrderState.Accepted:
            return resources.ACCEPTED;
        case OrderState.Rejected:
            return resources.REJECTED;
        default:
            return OrderState.toString();
    }
}
/**
 * Translate orderstate to options
 * @param {*} orderstate 
 */
export const getOrderStateDropdownOptions = () => {

    let options = [];
    for (var property in OrderState) 
        options.push({
            key: 'orderstate-' + property,
            text: getOrderStateText(OrderState[property]),
            value: OrderState[property]
        });

    return options;
   
}