import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import List from 'semantic-ui-react/dist/commonjs/elements/List';
import TextArea from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea';
import moment from 'moment';
import resources from '../../../resources';
import Log from '../models/Log';
import { loglevels } from '../common/LogLevels';

class LogForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            log: new Log()
        };

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.onchange = this.onchange.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState) {

        //If editing, or editing is changed, then update                          
        if( JSON.stringify(prevProps.editlog) !== JSON.stringify(this.props.editlog) && this.props.editlog !== null && this.props.editlog !== undefined ){
            this.setState({log:{...this.props.editlog}});
        }
        //If show is changed
        else if(prevProps.show !== this.props.show) {

            if(this.props.show === false)
                this.setState({ log: {...new Log()}});
        }

    }
    show() {
        const {showform} = this.props;
        showform();
    }
    close(e) {
        if(e) e.preventDefault();
        const { hideform } = this.props;
        this.setState({ log: {...new Log()} }, () => hideform());
    }
    onchange(key, value) {
        this.setState((prevState, props) => {
            return {
                log: { ...prevState.log, ...{ [key]: value } }
            };
        });
    }
    save() {
        const { log } = this.state;
        const { create, update } = this.props;

        if (log.Id !== 0) {
            update(log.Id, log);
        }
        else {
            create(log);
        }
    }
    render() {
        
        const { loading, show } = this.props;
        let { log } = this.state;
        let isNew = log.Id === 0;
        return (
            <React.Fragment>
       
            

            <Modal size='small' open={show} onClose={this.close} closeIcon closeOnDimmerClick={false}>
                <Modal.Header>
                {isNew ? resources.CREATE_NEW : resources.EDIT}
                </Modal.Header>
                <Modal.Content style={{ padding: '0' }}>

                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                        <Segment basic>
                            <Dimmer active={loading} inverted>
                                <Loader />
                            </Dimmer>
                            <List>

                                <List.Item>
                                    <Icon name='calendar' />
                                    <List.Content>
                                    <List.Header>{resources.CREATED}</List.Header>
                                    <List.Description>
                                        <br />
                                        {moment(log.Created).format('DD.MM.YYYY HH:mm:ss:Z')}
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <Icon name={log.LogLevel === loglevels.Info ? 'info circle' :
                                    (log.LogLevel === loglevels.Warning ? 'warning sign' :
                                        (log.LogLevel === loglevels.Error ? 'remove circle' : 'bug'))} />
                                    <List.Content>
                                    <List.Header>{resources.LOGLEVEL}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.LogLevel + ' ' + (log.LogLevel === loglevels.Info ? resources.INFO :
                                        (log.LogLevel === loglevels.Warning ? resources.WARNING :
                                        (log.LogLevel === loglevels.Error ? resources.ERROR : '')))} &nbsp;
                                        <br />
                                        <br /> 
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <Icon name='pencil' />
                                    <List.Content>
                                    <List.Header>{resources.TITLE}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.Title} &nbsp;
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <Icon name='ticket' />
                                    <List.Content>
                                    <List.Header>{resources.EVENT}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.EventId}&nbsp;
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item className='fullwidth'>
                                    <Icon name='info circle' />
                                    <List.Content>
                                    <List.Header>{resources.MESSAGE}</List.Header>
                                    <List.Description>
                                        <br />
                                        <TextArea rows={20} value={log.Message} />
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>


                                <List.Item>
                                    <Icon name='map pin' />
                                    <List.Content>
                                    <List.Header>{resources.SOURCE}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.Source} &nbsp;
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <Icon name='user circle' />
                                    <List.Content>
                                    <List.Header>{resources.USER}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.ApplicationUserId === '00000000-0000-0000-0000-000000000000' ? '' : log.ApplicationUserId}  &nbsp;
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>

                                <List.Item>
                                    <Icon name='group' />
                                    <List.Content>
                                    <List.Header>{resources.CLIENT_ID}</List.Header>
                                    <List.Description>
                                        <br />
                                        {log.ClientId} &nbsp;
                                        <br />
                                        <br />
                                    </List.Description>
                                    </List.Content>
                                </List.Item>


                                </List>
                        </Segment>
                        <Segment attached='bottom' secondary style={{ margin: '0', width: '100%' }}>
                            <Button onClick={this.close}>{resources.CANCEL}</Button>
                        </Segment>
                    </Form>

                </Modal.Content>

            </Modal> 
        </React.Fragment>
        );
    }
}
LogForm.propTypes = {
    editlog: PropTypes.object,
    text: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    showform: PropTypes.func.isRequired,
    hideform: PropTypes.func.isRequired,
    show: PropTypes.bool,
    clear: PropTypes.func.isRequired,
    loading: PropTypes.bool
}
  
export default LogForm;