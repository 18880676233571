import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TransitionablePortal from 'semantic-ui-react/dist/commonjs/addons/TransitionablePortal';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message';
import { hideToast } from '../actions/toast';



class ToastMessage extends Component {
    componentDidMount(){

        const {history, dispatch} = this.props;

        history.listen((location, action) => {
            // hide toast on history change
            dispatch(hideToast());
        });

    }

    handleClose = () => {
        this.props.dispatch(hideToast());
    }
    render(){
        const { title, message, color, show } = this.props;
        const animation = 'fly up';
        const duration = 500;
        
        //color = red orange yellow olive green teal blue violet purple pink brown grey black
        return (<TransitionablePortal open={show} transition={{ animation, duration }} onClose={this.handleClose}>
                    <Message floating className='toast-message' {...(color !== '' ? {color:color} : {})}> 
                        <Message.Header>{title}</Message.Header>
                        <p>{message}</p>
                    </Message>
                </TransitionablePortal>);
    }
}
ToastMessage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string
};
  
function mapStateToProps(state) {
    
    return { 
        title: state.toast.title,
        message: state.toast.message,
        color: state.toast.color,
        show: state.toast.show
    };
}

export default connect(mapStateToProps)(ToastMessage);
