
import ApiCall, { constructQueryUrl } from '../../../api/ApiCall';
import configuration from '../../../configuration';
import { showToast } from '../../../toast/actions/toast';
import resources from '../../../resources';
import { refreshSelectedOrderHeader } from './orderheader';
import { httpMethods } from '../../../api/ApiCall';
export const FETCH_ORDERROWS_STARTED = 'FETCH_ORDERROWS_STARTED';
export const FETCH_ORDERROWS_SUCCESS = 'FETCH_ORDERROWS_SUCCESS';
export const FETCH_ORDERROWS_FAILED = 'FETCH_ORDERROWS_FAILED';
export const FETCH_ORDERROW_STARTED = 'FETCH_ORDERROW_STARTED';
export const FETCH_ORDERROW_SUCCESS = 'FETCH_ORDERROW_SUCCESS';
export const FETCH_ORDERROW_FAILED = 'FETCH_ORDERROW_FAILED';
export const CREATE_ORDERROW_STARTED = 'CREATE_ORDERROW_STARTED';
export const CREATE_ORDERROW_SUCCESS = 'CREATE_ORDERROW_SUCCESS';
export const CREATE_ORDERROW_FAILED = 'CREATE_ORDERROW_FAILED';
export const UPDATE_ORDERROW_STARTED = 'UPDATE_ORDERROW_STARTED';
export const UPDATE_ORDERROW_SUCCESS = 'UPDATE_ORDERROW_SUCCESS';
export const UPDATE_ORDERROW_FAILED = 'UPDATE_ORDERROW_FAILED';
export const UPDATE_ORDERROW_STATE_STARTED = 'UPDATE_ORDERROW_STATE_STARTED';
export const UPDATE_ORDERROW_STATE_SUCCESS = 'UPDATE_ORDERROW_STATE_SUCCESS';
export const UPDATE_ORDERROW_STATE_FAILED = 'UPDATE_ORDERROW_STATE_FAILED';
export const DELETE_ORDERROW_STARTED = 'DELETE_ORDERROW_STARTED';
export const DELETE_ORDERROW_SUCCESS = 'DELETE_ORDERROW_SUCCESS';
export const DELETE_ORDERROW_FAILED = 'DELETE_ORDERROW_FAILED';
export const SHOW_ORDERROW_FORM = 'SHOW_ORDERROW_FORM';
export const HIDE_ORDERROW_FORM = 'HIDE_ORDERROW_FORM';
export const CLEAR_ORDERROW_EDIT = 'CLEAR_ORDERROW_EDIT';
export const ORDERROW_URL = configuration.ROOT_URL + '/api/v1/orderrow';

/**
 * Set showform=true, ui should show form based on this
 */
export function showOrderRowForm(){
    return { type: SHOW_ORDERROW_FORM }
}
/**
 * Set showform=false, ui should hide form based on this
 */
export function hideOrderRowForm(){
    return { type: HIDE_ORDERROW_FORM }
}
/**
 * Clear editing objects id
 */
export function clearEdit(){
    return { type: CLEAR_ORDERROW_EDIT }
}

function fetchOrderRowsStarted() {
    return { type: FETCH_ORDERROWS_STARTED }
}
function fetchOrderRowsSucceeded(orderrows) {
    return { type: FETCH_ORDERROWS_SUCCESS, payload:  {orderrows}  }
}
function fetchOrderRowsFailed(error) {
    return { type: FETCH_ORDERROWS_FAILED, payload: {error} }
}
/**
 * Get orderrows with queryparameters
 * @param {*} query 
 */
export function fetchOrderRows(query) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchOrderRowsStarted());
            const data = await new ApiCall().get(constructQueryUrl(ORDERROW_URL, query));
            dispatch(fetchOrderRowsSucceeded(data));
        } catch (e) {
            dispatch(fetchOrderRowsFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}

function fetchOrderRowStarted(id) {
    return { type: FETCH_ORDERROW_STARTED, payload:  {id}  }
}
function fetchOrderRowSucceeded(id,orderrow) {
    return { type: FETCH_ORDERROW_SUCCESS, payload: {id, orderrow}  }
}
function fetchOrderRowFailed(error) {
    return { type: FETCH_ORDERROW_FAILED, payload: {error} }
}
/**
 * Get orderrow by id
 * @param {*} id 
 */
export function fetchOrderRow(id) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchOrderRowStarted(id));
            const data = await new ApiCall().getbyid(id, ORDERROW_URL);
            dispatch(fetchOrderRowSucceeded(id,data));
        } catch (e) {
            dispatch(fetchOrderRowFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}


function createOrderRowStarted() {
    return { type: CREATE_ORDERROW_STARTED  }
}
function createOrderRowSucceeded(orderrow) {
    return { type: CREATE_ORDERROW_SUCCESS, payload:{orderrow}  }
}
function createOrderRowFailed(error) {
    return { type: CREATE_ORDERROW_FAILED, payload: {error} }
}
/**
 * Create orderrow
 * @param {*} orderrow : object 
 */
export function createOrderRow(orderrow) {
   
	return async dispatch => { 
        try { 
            dispatch(createOrderRowStarted())
            const data = await new ApiCall().create(ORDERROW_URL, orderrow);
            dispatch(createOrderRowSucceeded(data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideOrderRowForm());
            dispatch(refreshSelectedOrderHeader());
        } catch (e) {
            let msg = e.message;
            dispatch(createOrderRowFailed(msg));
            dispatch(showToast(resources.FAILED, msg,'red'));
        }
	}
}



function updateOrderRowStarted() {
    return { type: UPDATE_ORDERROW_STARTED  }
}
function updateOrderRowSucceeded(id,orderrow) {
    return { type: UPDATE_ORDERROW_SUCCESS, payload: {id,orderrow}  }
}
function updateOrderRowFailed(error) {
    return { type: UPDATE_ORDERROW_FAILED, payload: {error} }
}
/**
 * Update orderrow by id
 * @param {*} id 
 */
export function updateOrderRow(id,orderrow) {
    
	return async dispatch => { 
        try { 
            dispatch(updateOrderRowStarted())
            const data = await new ApiCall().update(ORDERROW_URL, id, orderrow);
            dispatch(updateOrderRowSucceeded(id,data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideOrderRowForm());
            dispatch(refreshSelectedOrderHeader());
        } catch (e) {
            dispatch(updateOrderRowFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}


function updateOrderRowStateStarted() {
    return { type: UPDATE_ORDERROW_STATE_STARTED  }
}
function updateOrderRowStateSucceeded(id,orderStatus) {
    return { type: UPDATE_ORDERROW_STATE_SUCCESS, payload: {id,orderStatus}  }
}
function updateOrderRowStateFailed(error) {
    return { type: UPDATE_ORDERROW_STATE_FAILED, payload: {error} }
}
/**
 * Update orderrow state by id
 * @param {*} id 
 */
export function updateOrderRowState(id,orderStatus) {
    
	return async dispatch => { 
        try { 
            dispatch(updateOrderRowStateStarted())
          
            let updateUrl = ORDERROW_URL;
            if(updateUrl.endsWith("/") === false)
            updateUrl += "/";

            updateUrl += id + "/" + orderStatus + "/"
            await new ApiCall().call(httpMethods.PUT,updateUrl,null);
  
            dispatch(updateOrderRowStateSucceeded(id,orderStatus));
            dispatch(showToast(resources.SAVED, '','green'));
        } catch (e) {
            dispatch(updateOrderRowStateFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}




function deleteOrderRowStarted() {
    return { type: DELETE_ORDERROW_STARTED  }
}
function deleteOrderRowSucceeded(id) {
    return { type: DELETE_ORDERROW_SUCCESS, payload: {id}  }
}
function deleteOrderRowFailed(error) {
    return { type: DELETE_ORDERROW_FAILED, payload: {error} }
}
/**
 * Delete by id
 * @param {*} id 
 */
export function deleteOrderRow(id) {
    
	return async dispatch => { 
        try { 
            dispatch(deleteOrderRowStarted())
            await new ApiCall().delete(ORDERROW_URL, id);
            dispatch(deleteOrderRowSucceeded(id));
            dispatch(showToast(resources.DELETED, '','green'));
            dispatch(hideOrderRowForm());
            dispatch(refreshSelectedOrderHeader());
        } catch (e) {
            dispatch(deleteOrderRowFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}
