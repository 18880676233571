import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';
import history from '../common/history';


const configureStore = preloadedState => {
  let middlewares = [
    routerMiddleware(history), // for dispatching history actions
    thunk
  ];

  if(process.env.NODE_ENV !== 'production'){
    middlewares = [...middlewares, createLogger()];
  }

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(applyMiddleware(...middlewares))

  );

  return store;
}

export default configureStore;
