/** Kategoria;Kustannuspaikka;Huone;Hyllypaikka;Tuotekoodi;Nimike;Varastosaldo;Myyntiyksikkö;VarastoYksikkö;Luotu;
 * OSKU;2344;45-33;;12A33;Tuotteen nimi;2;KPL;;20191011105922;
 * TARVIKKEET;2347;33-35;;12B47;Tuotteen nimi;5;PRK;;20191011105922;
 * Lääkkeet;2347;33-35;;12B47;Tuotteen nimi;3;PRK;;20191011105922;
 */
export const OrderImportColumns = {
    Category:0,
    CostPlace:1,
    Room:2,
    Place:3,
    ProductCode:4,
    ProductName:5,
    StorageQuantity:6,
    SalesUnit:7,
    StorageUnit:8,
    Created:9,
    ProductNameExtra:10,
    VendorProductCode:11,
    SalesPrice:12,
    StoragePrice:13
}