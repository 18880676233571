import moment from 'moment';
import { OrderState } from './../common/OrderState';
import { OrderType } from './../common/OrderType';

/**
 * OrderHeader
 * 
 * {
    "id": 4,
    "created": "2020-01-31T08:29:39.623000+00:00",
    "modified": null,
    "createdBy": "Admin",
    "modifiedBy": null,
    "costPlace": "2344",
    "room": "45-33",
    "orderStatus": 4,
    "orderType": 0,
    "rows": [ ...OrderRows... ]
    }
 */
export default class OrderHeader {
    constructor(
        id = 0, 
        created = '', 
        modified = null, 
        createdby = '',
        modifiedby = '',
        costplace = '',
        room = '',
        orderStatus = OrderState.Created,
        ordertype = OrderType.FromList,
        rows = []
        ) {

        this.id = id;
        this.createdBy = createdby;
        this.modifiedBy = modifiedby;
        this.created = created === '' ? moment().format('YYYY-MM-DD') : created;
        this.modified = modified === '' ? moment().format('YYYY-MM-DD') : modified;
        this.costPlace = costplace;
        this.room = room;
        this.orderStatus = orderStatus;
        this.rows = rows;
        this.orderType = ordertype;
    }    
}

