import React, { Component } from 'react';
import resources from './../resources';

class NoMatch extends Component {
  render() {
    return (
      <div>
        {resources.NO_MATCH}
      </div>
    );
  }
}

export default NoMatch;
