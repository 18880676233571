import React, { Component } from 'react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container/Container';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/dropdown.min.css';

/**

    Dynamic menucomponent

    1. Loads menu.json from server

    menu.json example:
    [
        { to: 'http://localhost:3000/',  text: { fi: 'Etusivu', en: 'FrontPage'}, roles:['Admin','SuperUser','User'] },
        { to: 'http://localhost:3000/about/', text: { fi: 'Tietoja', en: 'About' }, icon: 'ticket', roles:['Admin','SuperUser','User'] },
        { text: { fi: 'Valikko', en: 'Menu' }, items: [ { to: 'http://localhost:3001/', text: { fi: 'Käyttäjät', en: 'Users' }, icon: 'users', roles:['Admin','SuperUser','User'] } ] }
    ]
  
    2. Creates menu with given array of json objects inside menu.json

 **/
class DynamicMenu extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props){
        super(props);
        //Default state contains current location and array of menuitems (empty by default)
        this.state = {
            location:window.location.pathname,
            items:[]
        }
    }
    /**
     * When component mounts, load menu.json from server
     */
    componentDidMount() {
        const { baseurl, lang } = this.props;

        let menuUrl = '';
        if(baseurl)
            menuUrl = baseurl.endsWith("/") ? baseurl + 'menu.json' : baseurl + '/menu.json';
        else 
            menuUrl = '/menu.json';
        this.loadMenuItems(menuUrl, lang);
    }
    /**
     * Load menu.json from server and set json array response to state.
     * Also set default document title that shows in the browser titlebar
     * @param {url} url of menu.json
     * @param {lang} current active language, 'fi', 'en'
     */
    loadMenuItems = async (url, lang) => {
        let itemsFromServer = await fetch(url).then((response) => response.json()).catch((er) => { console.error(er); return [];});
        this.setState({items:itemsFromServer}, () => this.setDefaultTitle(itemsFromServer,lang));
    }
    /**
     * Find a menuitem by location url, menuitem can also be inside dropdown => child menuitems
     * @param {items} array of menuitems
     */
    findItemByCurrentLocation = (items) => {
        for(let i = 0; i < items.length; i++){
            
            if(items[i].to && items[i].to.replace(window.location.origin,'') === window.location.href.replace(window.location.origin,'')){
                return items[i];
            }
            else if(items[i].items){
                return this.findItemByCurrentLocation(items[i].items);
            }
        }
    }
    /**
     * Set default document title in browser header
     * @param {items} array of menuitems
     * @param {lang} current language, 'fi', 'en'
     */
    setDefaultTitle = (items, lang) => {
        let item = this.findItemByCurrentLocation(items);
        if(item) {
            this.setTitle(item.text[lang ? lang : 'fi']);
            this.setActive(item.to, item.text[lang ? lang : 'fi']);
        }
        else document.title = '';
        
    }
    /**
     * Set document title text in browser header
     * @param {text} text to be set in title
     */
    setTitle = (text) => {
        document.title = text;
    }
    /**
     * Set given menuitem as active, highlight
     * @param {to} location url
     * @param {text} text to be set in title
     */
    setActive = (to, text) => {
        this.setTitle(text);
        this.setState( {location:to} );
    }
    render() {
        const { link, lang, user, children, basepath } = this.props;
        const { location, items } = this.state;
        return (
            <Menu>
                <Container>
                {items.map((item, index) => {
                    return item.items && item.items.length > 0 ? 
                        (<DropDownMenuItem key={'drop-menu' + index + '-' + item.items[0].to} item={item} basepath={basepath} location={location} link={link} lang={lang} usersroles={user ? user.Roles : undefined} onClick={this.setActive} />) : 
                        (<MenuItem key={item.to} item={item} basepath={basepath} location={location} link={link} lang={lang} onClick={this.setActive} usersroles={user ? user.Roles : undefined} />)
                    })
                }
                {children}
                </Container>
            </Menu>
        );
    }
}
  
export default DynamicMenu;

/**
 * Get Link parameters
 * Check menu item to -url and compare it, if it belongs to current website or another
 * and return result json
 * 
 * 1. If different origin, check if menuitem url has http or https and origin is different
 *      - Return href with a menuitem url
 * 2. If different basepath => different webapp then current
 *      - Return href with a menuitem url
 * 2. If same web-application as current, then link is internal
 *      - Return to with a link
 * 
 * All other cases return normal link
 * 
 * @param {basepath} current basepath
 * @param {item} menuitem
 */
const getLinkParam = (item) => {

    let toWithoutOrigin = item.to.toLowerCase().replace(window.location.origin,'');
    let toBase = toWithoutOrigin.indexOf('#') > -1 ? toWithoutOrigin.substring(0,toWithoutOrigin.indexOf('#')) : toWithoutOrigin;

    if(toBase.startsWith('/') === false) toBase = '/' + toBase;
    if(toBase.endsWith('/') === false) toBase += '/';

    //Origin
    let isDifferentOrigin = (item.to.toLowerCase().startsWith("http://") || item.to.toLowerCase().startsWith("https://"))
                            && item.to.startsWith(window.location.origin) === false;

    //Check if another web-app in same domain
    // If current location is same as this application base... and if the link has different base => link in another web-app
    let isAnotherWebApp = window.location.pathname === '/' ? window.location.pathname !== toBase : toWithoutOrigin.startsWith(window.location.pathname) === false;

    if(isDifferentOrigin || isAnotherWebApp) return { href: item.to };
    
    //Check if current web-app
    // If current location is same as this application base... and if the link has same base => link in current web-app
    let isCurrentWebApp = window.location.pathname === '/' ? window.location.pathname === toBase : toWithoutOrigin.startsWith(window.location.pathname);

    if(isCurrentWebApp){
        //"/identity/#/logs"
        let cleanedUrl = toWithoutOrigin.replace(window.location.pathname,'');
        if(cleanedUrl.indexOf("#") > -1) cleanedUrl = cleanedUrl.substring(cleanedUrl.indexOf('#')+1);

        return { to:cleanedUrl };
    }
    
    return { href: item.to };
}


/**
 * One menuitem in menu
 * @param {item} menuitem
 * @param {lang} menu-language, 'fi', 'en'
 * @param {location} current -location url
 * @param {link} link component, menu can be a link component if used within same website
 * @param {usersroles} array of userroles 
 * @param {onClick} menu onclick, setup active menuitem 
 */
export const MenuItem = ({item, lang, location, link, usersroles, onClick}) => ((item.roles === undefined || item.roles.length === 0) || (usersroles === undefined) || (item.roles && item.roles.findIndex(i => usersroles.includes(i)) > -1) ) ? 
                                                                                (<Menu.Item as={ getLinkParam(item).href ? 'a' : link}
                                                                                    key={item.to}
                                                                                    active={item.to.replace(window.location.origin,'') === location.replace(window.location.origin,'')}
                                                                                    onClick={() => onClick(item.to, item.text[lang ? lang : 'fi'])}
                                                                                    {...getLinkParam(item)}>
                                                                                    {item.icon && <Icon name={item.icon}>&nbsp;</Icon>} {item.text[lang ? lang : 'fi']}
                                                                                </Menu.Item>) : (null);
/**
 * Dropdown menuitem, that contains multiple menuitems
 * @param {item} menuitem
 * @param {lang} menu-language, 'fi', 'en'
 * @param {location} current -location url
 * @param {link} link component, menu can be a link component if used within same website
 * @param {usersroles} array of userroles 
 * @param {onClick} menu onclick, setup active menuitem 
 */
export const DropDownMenuItem = ({item, lang, location, link, usersroles, onClick}) => <Dropdown item simple text={item.text[lang ? lang : 'fi']}>
                                                                                            <Dropdown.Menu>
                                                                                                {item.items && item.items.map((subitem) => <MenuItem key={subitem.to} item={subitem} location={location} link={link} lang={lang} onClick={onClick} usersroles={usersroles} />)}
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>;

