import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import resources from '../../../resources';
import OrderHeader from '../models/OrderHeader';
import { Dropdown } from 'semantic-ui-react';
import { getOrderStateDropdownOptions } from '../common/OrderState';
import moment from 'moment';
import { getOrderTypeDropdownOptions } from '../common/OrderType';

class OrderHeaderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderheader: { ...new OrderHeader(), createdBy:this.props.user ? this.props.user : ''}
        };

    }
    
    componentDidUpdate(prevProps, prevState) {

        //If editing, or editing is changed, then update                          
        if( JSON.stringify(prevProps.edititem) !== JSON.stringify(this.props.edititem) && this.props.edititem !== null && this.props.edititem !== undefined ){
            this.setState({orderheader:{...this.props.edititem, modifiedBy:this.props.user ? this.props.user : ''}});
        }
        //If show is changed
        else if(prevProps.show !== this.props.show) {

            if(this.props.show === false)
                this.setState({ orderheader: { ...new OrderHeader(), createdBy:this.props.user ? this.props.user : ''} });
        }

    }
 
    close = (e) => {
        if(e) e.preventDefault();
        const { hideform } = this.props;
        this.setState({ orderheader: { ...new OrderHeader(), createdBy:this.props.user ? this.props.user : ''} }, () => hideform());
    }
    onchange = (key, value) => {
        this.setState((prevState, props) => {
            return {
                orderheader: { ...prevState.orderheader, ...{ [key]: value } }
            };
        });
    }
    save = () => {
        const { orderheader } = this.state;
        const { create, update } = this.props;


        if (orderheader.id !== 0) {
            orderheader.modified = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            update(orderheader.id, {...orderheader});
        }
        else {
            orderheader.created = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            create(orderheader);
        }
    }
    render() {
        
        const {  text, size, loading, show } = this.props;
        let { orderheader } = this.state;
        let isNew = orderheader.id === 0;
        return (
            <React.Fragment>
     
            <Button icon size={size ? size : 'mini'}  style={{whiteSpace:'nowrap',minHeight:'2.9em'}} onClick={() => this.props.showform()} basic>
                <Icon name='add circle' />&nbsp;
                {text !== undefined ? text : resources.CREATE_NEW}
            </Button>

            <Modal size='small' open={show} onClose={this.close} closeIcon closeOnDimmerClick={false}>
                <Modal.Header>
                {isNew ? resources.CREATE_NEW : resources.EDIT}
                </Modal.Header>
                <Modal.Content style={{ padding: '0' }}>
                
                    <Form onSubmit={(e) => { e.preventDefault(); this.save(); }}>
                        <Segment basic>
                            <Dimmer active={loading} inverted>
                                <Loader />
                            </Dimmer>

                                <Form.Field required>
                                    <label>{resources.ORDERLIST_NUMBER}</label>
                                    <input placeholder={resources.ORDERLIST_NUMBER}  required readOnly style={{color:'gray',background:'#ececec'}}
                                        type='text' value={orderheader.id === null ? '' : orderheader.id}
                                        onChange={(e) => this.onchange('id', e.target.value)} />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{resources.COST_PLACE}</label>
                                    <input placeholder={resources.COST_PLACE} required
                                        type='text' value={orderheader.costPlace === null ? 0 : orderheader.costPlace}
                                        onChange={(e) => this.onchange('costPlace', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.ROOM}</label>
                                    <input placeholder={resources.ROOM}
                                        type='text' value={orderheader.room === null ? '' : orderheader.room}
                                        onChange={(e) => this.onchange('room', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.TYPE}</label>
                                    <Dropdown placeholder={resources.TYPE}
                                                fluid value={orderheader.orderType}
                                                selection onChange={(e, { value }) => this.onchange('orderType', value)}
                                                options={getOrderTypeDropdownOptions()} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{resources.STATE}</label>
                                    <Dropdown placeholder={resources.STATE}
                                                fluid value={orderheader.orderStatus}
                                                selection onChange={(e, { value }) => this.onchange('orderStatus', value)}
                                                options={getOrderStateDropdownOptions()} />
                                </Form.Field>
                                
                        </Segment>
                        <Segment attached='bottom' secondary style={{ margin: '0', width: '100%' }}>
                            <Button positive icon='save' type='submit' labelPosition='left' content={resources.SAVE} disabled={loading} />
                            <Button onClick={this.close}>{resources.CANCEL}</Button>
                        </Segment>
                    </Form>

                </Modal.Content>

            </Modal> 
        </React.Fragment>
        );
    }
}
OrderHeaderForm.propTypes = {
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    edititem: PropTypes.object,
    text: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    showform: PropTypes.func.isRequired,
    hideform: PropTypes.func.isRequired,
    show: PropTypes.bool,
    clear: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    user: PropTypes.string
}
  
export default OrderHeaderForm;