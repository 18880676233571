
import ApiCall, { constructQueryUrl } from '../../../api/ApiCall';
import configuration from '../../../configuration';
import { showToast } from '../../../toast/actions/toast';
import resources from '../../../resources';
export const FETCH_LOGS_STARTED = 'FETCH_LOGS_STARTED';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_FAILED = 'FETCH_LOGS_FAILED';
export const FETCH_LOG_STARTED = 'FETCH_LOG_STARTED';
export const FETCH_LOG_SUCCESS = 'FETCH_LOG_SUCCESS';
export const FETCH_LOG_FAILED = 'FETCH_LOG_FAILED';
export const DELETE_LOGS_STARTED = 'DELETE_LOGS_STARTED';
export const DELETE_LOGS_SUCCESS = 'DELETE_LOGS_SUCCESS';
export const DELETE_LOGS_FAILED = 'DELETE_LOGS_FAILED';
export const SHOW_LOG_FORM = 'SHOW_LOG_FORM';
export const HIDE_LOG_FORM = 'HIDE_LOG_FORM';
export const CLEAR_EDIT = 'CLEAR_EDIT';
export const LOGS_URL = configuration.ROOT_URL + '/api/v1/log';

/**
 * Set showform=true, ui should show form based on this
 */
export function showLogForm(editid){
    return { type: SHOW_LOG_FORM, payload:{id: (editid ? editid : 0)} }
}
/**
 * Set showform=false, ui should hide form based on this
 */
export function hideLogForm(){
    return { type: HIDE_LOG_FORM }
}
/**
 * Clear editing objects id
 */
export function clearLogEdit(){
    return { type: CLEAR_EDIT }
}

function fetchLogsStarted() {
    return { type: FETCH_LOGS_STARTED }
}
function fetchLogsSucceeded(logs) {
    return { type: FETCH_LOGS_SUCCESS, payload:  {logs}  }
}
function fetchLogsFailed(error) {
    return { type: FETCH_LOGS_FAILED, payload: {error} }
}
/**
 * Get logs with queryparameters
 * @param {*} query 
 */
export function fetchLogs(query) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchLogsStarted())
            const data = await new ApiCall().get(constructQueryUrl(LOGS_URL, query))
            dispatch(fetchLogsSucceeded(data))
        } catch (e) {
            dispatch(fetchLogsFailed(e.message))
        }
	}
}

function fetchLogStarted(id) {
    return { type: FETCH_LOG_STARTED, payload:  {id}  }
}
function fetchLogSucceeded(id,log) {
    return { type: FETCH_LOG_SUCCESS, payload: {id, log}  }
}
function fetchLogFailed(error) {
    return { type: FETCH_LOG_FAILED, payload: {error} }
}
/**
 * Get log by id
 * @param {*} id 
 */
export function fetchLog(id) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchLogStarted(id))
            const data = await new ApiCall().getbyid(id, LOGS_URL)
            dispatch(fetchLogSucceeded(id,data))
        } catch (e) {
            dispatch(fetchLogFailed(e.message))
        }
	}
}


function deleteLogStarted() {
    return { type: DELETE_LOGS_STARTED  }
}
function deleteLogSucceeded(query) {
    return { type: DELETE_LOGS_SUCCESS, payload: {query}  }
}
function deleteLogFailed(error) {
    return { type: DELETE_LOGS_FAILED, payload: {error} }
}
/**
 * Delete logs by query
 * @param {*} id 
 */
export function deleteLogs(query) {
    
	return async dispatch => { 
        try { 
            dispatch(deleteLogStarted())
            await new ApiCall().deletebyurl(constructQueryUrl(LOGS_URL, query));
            dispatch(deleteLogSucceeded(query));
            dispatch(showToast(resources.DELETED, '','green'));
            dispatch(hideLogForm());
        } catch (e) {
            dispatch(deleteLogFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}
