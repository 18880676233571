import React, { Component } from 'react';
import PropTypes from 'prop-types';
import resources from '../../../resources';
import Menu  from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

class OrderHeaderSearch extends Component {
    constructor(props){
        super(props);
        this.searchword = React.createRef();
    }
    search = (e) => {
        
        let searchWord = this.searchword.current.inputRef.current.value;
      
        const { search } = this.props;
        if(this.searchTimeout && this.searchTimeout !== null){
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
        this.searchTimeout = setTimeout(() => {
            search(searchWord);
        }, 1000);
    }
    render() { 
        const { children, right, searchword } = this.props;
        return (<Menu secondary stackable>
               {children}
                <Menu.Menu position='right'>
                    {right}
                    <Menu.Item>
                        <Input icon='search' placeholder={resources.SEARCH + '...'} defaultValue={searchword} ref={this.searchword} onChange={this.search} />
                    </Menu.Item>
                </Menu.Menu>
                </Menu>);
    } 
}
OrderHeaderSearch.propTypes = {
    children: PropTypes.any,//not required
    search: PropTypes.func.isRequired
}
  
export default OrderHeaderSearch;