import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import resources from '../../../resources';
import { getOrderStateText } from '../common/OrderState';
import moment from 'moment';
class OrderHeaderDetails extends Component {

    render() {
        
        let { orderheader } = this.props;
        
        return (
            <React.Fragment>

                <Form>
                   
                <Grid stackable columns={2}>
                    <Grid.Row>
                    <Grid.Column>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{resources.ORDERLIST_NUMBER}</label>
                                <input value={orderheader.id} readOnly />
                            </Form.Field>
                            <Form.Field>
                                <label>{resources.COST_PLACE}</label>
                                <input value={orderheader.costPlace} readOnly />
                            </Form.Field>
                            <Form.Field>
                                <label>{resources.ROOM}</label>
                                <input value={orderheader.room} readOnly />
                            </Form.Field>
                        </Form.Group>
                        
                    </Grid.Column>
                    <Grid.Column>
                    
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{resources.CREATED_BY}</label>
                                <input value={orderheader.createdBy} readOnly />
                            </Form.Field>
                            <Form.Field>
                                <label>{resources.CREATED}</label>
                                <input value={moment(orderheader.created).format('DD.MM.YYYY HH:mm:ss')} readOnly />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column>
                    <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{resources.STATE}</label>
                                <input value={getOrderStateText(orderheader.orderStatus)} readOnly />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column>
                    <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{resources.MODIFIED_BY}</label>
                                <input value={orderheader.modifiedBy === null ? '' : orderheader.modifiedBy} readOnly />
                            </Form.Field>
                            <Form.Field>
                                <label>{resources.UPDATED}</label>
                                <input value={orderheader.modified === null ? '' : moment(orderheader.modified).format('DD.MM.YYYY HH:mm:ss')} readOnly />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Form>
            </React.Fragment>
        );
    }
}
OrderHeaderDetails.propTypes = {
    orderheader: PropTypes.object,
    back: PropTypes.func,
}
  
export default OrderHeaderDetails;