import { loglevels } from "../common/LogLevels";

export default class Log {
    constructor(id = 0, created='', eventid='', loglevel = loglevels.Info, title='', message='', source='', applicationuserid='', clientid='') {
        this.Id = id;
        this.Created = created;
        this.EventId = eventid;
        this.LogLevel = loglevel;
        this.Title = title;
        this.Message = message;
        this.Source = source;
        this.ApplicationUserId = applicationuserid;
        this.ClientId = clientid;
    }    
}
