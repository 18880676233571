import moment from 'moment';

/**
 * OrderRow
 * 
 * {
      "id": 10,
      "orderHeaderId": 4,
      "created": "2019-05-10T21:00:00.000000+00:00",
      "modified": "2020-01-31T08:41:19.886000+00:00",
      "createdBy": "Admin",
      "modifiedBy": "Admin",
      "category": "TARVIKKEET",
      "place": "2",
      "productCode": "12B47123",
      "productName": "Tuotteen nimi 2",
      "storageQuantity": 5,
      "salesUnit": "PRK",
      "storageUnit": "PRK",
      "orderStatus": 4,
      "productNameExtra": "",
      "vendor": "",
      "vendorProductCode": "",
      "salesPrice": 0,
      "storagePrice": 0,
      "orderQuantity": 0
    }
 */

export default class OrderRow {
    constructor(
        id = 0, 
        orderheaderid = 0,
        created = '', 
        modified = null, 
        createdby = '',
        modifiedby = '',
        category = '',
        place = '',
        productcode = '',
        productname = '',
        storagequantity = 0,
        salesunit = '',
        storageunit = '',
        orderStatus = 0,
        productnameextra = '',
        vendor = '',
        vendorproductcode = '',
        salesprice = 0,
        storageprice = 0,
        orderquantity = 0
        ) {

        this.id = id;
        this.orderHeaderId = orderheaderid;
        this.category = category;
        this.place = place;
        this.productCode = productcode;
        this.productName = productname;
        this.storageQuantity = storagequantity;
        this.salesUnit = salesunit;
        this.storageUnit = storageunit;
        this.created = created === '' ? moment().format('YYYY-MM-DD') : created;
        this.modified = modified;
        this.createdBy = createdby;
        this.modifiedBy = modifiedby;
        this.orderStatus = orderStatus;
        this.productNameExtra = productnameextra;
        this.vendor = vendor;
        this.vendorProductCode = vendorproductcode;
        this.salesPrice = salesprice;
        this.storagePrice = storageprice;
        this.orderQuantity = orderquantity;

    }    
}

