import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header/Header';
import MenuItem from 'semantic-ui-react/dist/commonjs/collections/Menu/MenuItem';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import resources from '../../../resources';
import { CSVLink } from "react-csv";
import { int32 } from '../../../common/int32';
import { getOrderHeader } from '../reducers/orderheader';
import OrderCsvParser from '../common/OrderCsvParser';
import { fetchOrderRows, fetchOrderRow, createOrderRow, updateOrderRow, clearEdit, showOrderRowForm, hideOrderRowForm, deleteOrderRow, updateOrderRowState } from '../actions/orderrow';
import { getOrderRow, filterOrderRows } from '../reducers/orderrow';
import OrderRowTable, { constructOrderRowTableHeaders, constructOrderRowTableRows } from '../components/OrderRowTable';
import OrderRowForm from '../components/OrderRowForm';
import { showToast } from '../../../toast/actions/toast';
import OrderHeaderDetails from '../components/OrderHeaderDetails';
import { clearSelectedOrderHeader, fetchOrderHeader, clearEdit as clearHeaderEdit } from '../actions/orderheader';
import { Menu, Segment } from 'semantic-ui-react';
import { updateOrderHeaderState } from '../actions/orderheader';
import { OrderState } from '../common/OrderState';
import OrderRowSearch from './OrderRowSearch';
import moment from 'moment';


class OrderManagement extends Component {
  constructor(props) {
      super(props); 
      this.state = {
          search: '',
          sortcolumn: 'ProductCode',
          sortorder: 'asc',
          loadingCsv:false,
          csvData:null,
          csvHeaders:null
      }; 
      
      this.fileRef = React.createRef();
      this.csvDownloadRef = React.createRef();
  }
  componentDidMount(){
    //this.loadData(); Dont load here
  }
  sortby = (col, order) => {
    this.setState({ sortcolumn: col, sortorder: order });
  }
  search = (searchword) => this.setState({search:searchword});
   /***
   * Create csv from resultset,
   * - Load all data in database for this query
   * - Conver headers for csv from current table in view
   * - Convert table data rows for csv from current table in view
   */
  getCsv = (e) => {
    e.preventDefault();
    const {loadingCsv,search, sortcolumn, sortorder} = this.state;
    if(loadingCsv) return;
    const { items, toast } = this.props;
    let filteredItems = filterOrderRows(search, items, sortcolumn, sortorder);

    this.setState({ loadingCsv: true }, async () => {
        try{
            //Get data and headers
            let data = new OrderCsvParser().getCsvBydata(filteredItems, constructOrderRowTableHeaders, constructOrderRowTableRows)
            
            //Set to state and simulate click, and then clear state 
            this.setState({ csvData: data.data, csvHeaders:data.headers }, () => {
                if (this.csvDownloadRef && this.csvDownloadRef.current && this.csvDownloadRef.current.link)
                    this.csvDownloadRef.current.link.click();

                this.setState({ csvData: null, csvHeaders:null, loadingCsv: false });
            });
                
        }
        catch(error){
            this.setState({ csvData: null, csvHeaders:null, loadingCsv: false }, () => {
                toast(resources.TO_CSV, resources.ERROR + ': ' + error.message ? error.message : error, 'red');
            });
        }
    });
  }
 
  getbyid = (id) =>{
        this.props.showform();
        this.props.fetchItem(id);
  }
  remove = (id) =>{
      if(window.confirm(resources.ARE_YOU_SURE)){
          this.props.remove(id);
      }
  }
  hideform = () =>{
    this.props.hideform();
    this.props.clearRow();
  }
  accept = () => {
    if(window.confirm(resources.ARE_YOU_SURE)){
        const { updateHeaderState, updateRowState, items, header } = this.props;
        updateHeaderState(header.id,OrderState.Accepted);
        for(let x = 0; x < items.length; x++){
            updateRowState(items[x].id,OrderState.Accepted);
        }
    }
  }
  reject = () => {
    if(window.confirm(resources.ARE_YOU_SURE)){
        const { updateHeaderState, updateRowState, items, header } = this.props;
        updateHeaderState(header.id,OrderState.Rejected);
        for(let x = 0; x < items.length; x++){
            updateRowState(items[x].id,OrderState.Rejected);
        }
    }
  }
  render() {
    const { loadingCsv,csvData,csvHeaders, search, sortcolumn, sortorder } = this.state;
    const { items, loading, showform, show, editrow, username, header,clear,clearRow,update,create } = this.props;

   
    let filteredItems = filterOrderRows(search, items, sortcolumn, sortorder);
    return (
      <React.Fragment>
       
        <Menu stackable attached='top'>
            <MenuItem onClick={clear}>
                <Icon name='angle left' /> {resources.GO_BACK}
            </MenuItem>
            <MenuItem>
                <Header as='h3' className='finnid-header'>
                {resources.ORDER}: {header.id}/{header.costPlace}
                </Header>
            </MenuItem>
        </Menu>
        <Segment attached='bottom'>
            <OrderHeaderDetails orderheader={header} /> 
          
            <OrderRowSearch search={this.search} searchword={search} 
                    right={<MenuItem>
                                {(header.orderStatus !== OrderState.Accepted && header.orderStatus !== OrderState.Rejected) && <Button basic positive onClick={this.accept} style={{whiteSpace:'nowrap',minHeight:'2.9em'}}>{resources.ACCEPT_ORDER}</Button>}&nbsp;
                                {(header.orderStatus !== OrderState.Accepted && header.orderStatus !== OrderState.Rejected) && <Button basic negative onClick={this.reject} style={{whiteSpace:'nowrap',minHeight:'2.9em'}}>{resources.REJECT_ORDER}</Button>}&nbsp;

                                {(header.orderStatus !== OrderState.Accepted && header.orderStatus !== OrderState.Rejected) && <OrderRowForm text={resources.CREATE_NEW_ROW} headerId={header.id} 
                                            size='medium' hideform={this.hideform} showform={showform}
                                            edititem={editrow} loading={loading} show={show} user={username}
                                            create={create} update={update} clear={clearRow} />}
                                &nbsp;
                                <CSVLink ref={this.csvDownloadRef} 
                                            headers={csvHeaders} 
                                            filename={resources.ORDER + '_' + header.costPlace + '_' + moment(header.modified || header.created).format('DD_MM_YYYY') + '.csv'} data={csvData === null ? [] : csvData} 
                                            separator={";"}></CSVLink>
                                <Button basic style={{whiteSpace:'nowrap',minHeight:'2.9em'}} onClick={this.getCsv}><Icon name={loadingCsv ? 'spinner' : 'file excel outline'} loading={loadingCsv} /> {resources.TO_CSV}</Button>
                            </MenuItem>
                            }>                          
                <MenuItem>
                    <Header as='h3' className='finnid-header'>
                        {resources.ORDERROW_TITLE}
                    </Header>
                </MenuItem>
            </OrderRowSearch>
            <OrderRowTable
                loading={loading}
                data={filteredItems}
                sortby={this.sortby}
                pageSize={int32.MAX}
                hidePaging={true}
                loadData={this.loadData}
                mobilecolumns={2}
                tabletcolumns={8}
                remove={this.remove}
                getbyid={this.getbyid}
                editable={header == null || (header.orderStatus !== OrderState.Accepted && header.orderStatus !== OrderState.Rejected)}
                />

    </Segment>
      </React.Fragment>
    );
  }
}

OrderManagement.propTypes = {
    username: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchItem: PropTypes.func.isRequired,
    fetchHeader: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    clearRow: PropTypes.func.isRequired,
    showform: PropTypes.func.isRequired,
    hideform: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    header: PropTypes.object,
    editrow: PropTypes.object,
    show: PropTypes.bool.isRequired,
    toast: PropTypes.func.isRequired,
    updateHeaderState: PropTypes.func.isRequired,
    updateRowState: PropTypes.func.isRequired
};
const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        fetch: (query) => dispatch(fetchOrderRows(query)),
        fetchItem: (id) => dispatch(fetchOrderRow(id)),
        fetchHeader: (id) => dispatch(fetchOrderHeader(id)),
        create: (item) => dispatch(createOrderRow(item)),
        update: (id,item) => dispatch(updateOrderRow(id, item)),
        clear: () => {
            dispatch(clearEdit())
            dispatch(clearHeaderEdit())
            dispatch(clearSelectedOrderHeader())
        },
        clearRow: () => {
            dispatch(clearEdit())
        },
        showform: () => dispatch(showOrderRowForm()),
        hideform: () => dispatch(hideOrderRowForm()),
        remove: (id) => dispatch(deleteOrderRow(id)),
        toast: (title,msg,color) => dispatch(showToast(title,msg,color)),
        updateHeaderState: (id,status) => dispatch(updateOrderHeaderState(id,status)),
        updateRowState: (id,status) => dispatch(updateOrderRowState(id,status))
    }





}
function mapStateToProps(state) {
    let orderHeader = getOrderHeader(state.orderheader.selectedid, state.orderheader.orderheaders);
    let edititem = getOrderRow(state.orderrow.editid, state.orderrow.orderrows);
    return { 
        header: orderHeader,
        username: state.login.user ? state.login.user.UserName : '',
        items: orderHeader ? orderHeader.rows : [],
        loading: state.orderrow.loading || state.orderheader.loading,
        show: state.orderrow.showform,
        editrow: edititem
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderManagement); 