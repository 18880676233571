
import { 
    LOGIN_STARTED, LOGIN_FAILURE, LOGIN_SUCCESS, 
    LOGOUT_STARTED, LOGOUT_FAILURE, LOGOUT_SUCCESS
 } from './../actions/login';


/**
 * Users initialstate
 */
const initialState = {
    loading:false,
    error:'',
    logout:false,
    loginsuccess:false,
    user:null,
};

/**
 * Users reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_STARTED:
        return {
            ...state,
            loading:true,
            logout:false,
            user:null
        };
    case LOGIN_FAILURE:
        return {
            ...state,
            loading:false,
            loginsuccess:false,
            logout:false,
            error:action.payload,
            user:null
        };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading:false,
        loginsuccess:true,
        logout:false,
        user: action.payload.user
      };
    case LOGOUT_STARTED:
        return {
            ...state,
            loading:true,
            logout:false
        };
    case LOGOUT_FAILURE:
        return {
            ...state,
            loading:false,
            logout:false,
            error:action.payload
        };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading:false,
        loginsuccess:false,
        logout:true,
        error:'',
        user: null
      };
    
    default:
      return state;
  }
}


