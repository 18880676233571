

import { SHOW_TOAST, HIDE_TOAST } from '../actions/toast';

/**
 * Toast initialstate
 */
const initialState = {
    title: '',
    message: '',
    color: '',
    show: false
};

/**
 * toast reducers
 * @param {*} state 
 * @param {*} action 
 */
export default function toast(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:{
        return {
            ...state,
            title:action.payload.title,
            message:action.payload.message,
            color:action.payload.color,
            show:true
        }
    }
    case HIDE_TOAST:{
        return {
            ...state,
            show:false
        }
    }
    default:
      return state;
  }
}
