
import ApiCall, { constructQueryUrl } from '../../../api/ApiCall';
import configuration from '../../../configuration';
import { showToast } from '../../../toast/actions/toast';
import resources from '../../../resources';
import { httpMethods } from '../../../api/ApiCall';
export const FETCH_ORDERHEADERS_STARTED = 'FETCH_ORDERHEADERS_STARTED';
export const FETCH_ORDERHEADERS_SUCCESS = 'FETCH_ORDERHEADERS_SUCCESS';
export const FETCH_ORDERHEADERS_FAILED = 'FETCH_ORDERHEADERS_FAILED';
export const FETCH_ORDERHEADER_STARTED = 'FETCH_ORDERHEADER_STARTED';
export const FETCH_ORDERHEADER_SUCCESS = 'FETCH_ORDERHEADER_SUCCESS';
export const FETCH_ORDERHEADER_FAILED = 'FETCH_ORDERHEADER_FAILED';
export const CREATE_ORDERHEADER_STARTED = 'CREATE_ORDERHEADER_STARTED';
export const CREATE_ORDERHEADER_SUCCESS = 'CREATE_ORDERHEADER_SUCCESS';
export const CREATE_ORDERHEADER_FAILED = 'CREATE_ORDERHEADER_FAILED';
export const UPDATE_ORDERHEADER_STARTED = 'UPDATE_ORDERHEADER_STARTED';
export const UPDATE_ORDERHEADER_SUCCESS = 'UPDATE_ORDERHEADER_SUCCESS';
export const UPDATE_ORDERHEADER_FAILED = 'UPDATE_ORDERHEADER_FAILED';
export const UPDATE_ORDERHEADER_STATE_STARTED = 'UPDATE_ORDERHEADER_STATE_STARTED';
export const UPDATE_ORDERHEADER_STATE_SUCCESS = 'UPDATE_ORDERHEADER_STATE_SUCCESS';
export const UPDATE_ORDERHEADER_STATE_FAILED = 'UPDATE_ORDERHEADER_STATE_FAILED';
export const DELETE_ORDERHEADER_STARTED = 'DELETE_ORDERHEADER_STARTED';
export const DELETE_ORDERHEADER_SUCCESS = 'DELETE_ORDERHEADER_SUCCESS';
export const DELETE_ORDERHEADER_FAILED = 'DELETE_ORDERHEADER_FAILED';
export const SHOW_ORDERHEADER_FORM = 'SHOW_ORDERHEADER_FORM';
export const HIDE_ORDERHEADER_FORM = 'HIDE_ORDERHEADER_FORM';
export const CLEAR_ORDERHEADER_EDIT = 'CLEAR_ORDERHEADER_EDIT';
export const SELECT_ORDERHEADER_SELECT = 'SELECT_ORDERHEADER_SELECT';
export const CLEAR_ORDERHEADER_SELECT = 'CLEAR_ORDERHEADER_SELECT';
export const PATCH_ORDERHEADER_STARTED = 'PATCH_ORDERHEADER_STARTED';
export const PATCH_ORDERHEADER_SUCCESS = 'PATCH_ORDERHEADER_SUCCESS';
export const PATCH_ORDERHEADER_FAILED = 'PATCH_ORDERHEADER_FAILED';
export const ORDERHEADER_URL = configuration.ROOT_URL + '/api/v1/orderheader';

/**
 * Set showform=true, ui should show form based on this
 */
export function showOrderHeaderForm(){
    return { type: SHOW_ORDERHEADER_FORM }
}
/**
 * Set showform=false, ui should hide form based on this
 */
export function hideOrderHeaderForm(){
    return { type: HIDE_ORDERHEADER_FORM }
}
/**
 * Clear editing objects id
 */
export function clearEdit(){
    return { type: CLEAR_ORDERHEADER_EDIT }
}

/**
 * Select id
 */
export function selectOrderHeader(id){
    return { type: SELECT_ORDERHEADER_SELECT, payload: {id} }
}
/**
 * Refresh from rest
 */
export function refreshSelectedOrderHeader(){
    return async (dispatch, getState) => { 
        let selectedOrderHeader = getState().orderheader.selectedid;
        if(selectedOrderHeader > 0) return dispatch(fetchOrderHeader(selectedOrderHeader))
	}
}
/**
 * Clear selected id
 */
export function clearSelectedOrderHeader(){
    return { type: CLEAR_ORDERHEADER_SELECT }
}

function fetchOrderHeadersStarted() {
    return { type: FETCH_ORDERHEADERS_STARTED }
}
function fetchOrderHeadersSucceeded(orderheaders) {
    return { type: FETCH_ORDERHEADERS_SUCCESS, payload:  {orderheaders}  }
}
function fetchOrderHeadersFailed(error) {
    return { type: FETCH_ORDERHEADERS_FAILED, payload: {error} }
}
/**
 * Get orders with queryparameters
 * @param {*} query 
 */
export function fetchOrderHeaders(query) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchOrderHeadersStarted());
            const data = await new ApiCall().get(constructQueryUrl(ORDERHEADER_URL, query));
            dispatch(fetchOrderHeadersSucceeded(data));
        } catch (e) {
            dispatch(fetchOrderHeadersFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}

function fetchOrderHeaderStarted(id) {
    return { type: FETCH_ORDERHEADER_STARTED, payload:  {id}  }
}
function fetchOrderHeaderSucceeded(id,orderheader) {
    return { type: FETCH_ORDERHEADER_SUCCESS, payload: {id, orderheader}  }
}
function fetchOrderHeaderFailed(error) {
    return { type: FETCH_ORDERHEADER_FAILED, payload: {error} }
}
/**
 * Get orderheader by id
 * @param {*} id 
 */
export function fetchOrderHeader(id) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchOrderHeaderStarted(id));
            const data = await new ApiCall().getbyid(id, ORDERHEADER_URL);
            dispatch(fetchOrderHeaderSucceeded(id,data));
        } catch (e) {
            dispatch(fetchOrderHeaderFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}


function createOrderHeaderStarted() {
    return { type: CREATE_ORDERHEADER_STARTED  }
}
function createOrderHeaderSucceeded(orderheader) {
    return { type: CREATE_ORDERHEADER_SUCCESS, payload:{orderheader}  }
}
function createOrderHeaderFailed(error) {
    return { type: CREATE_ORDERHEADER_FAILED, payload: {error} }
}
/**
 * Create order
 * @param {*} orderheader : object 
 */
export function createOrderHeader(orderheader) {
   
	return async dispatch => { 
        try { 
            dispatch(createOrderHeaderStarted())
            const data = await new ApiCall().create(ORDERHEADER_URL, orderheader);
            dispatch(createOrderHeaderSucceeded(data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideOrderHeaderForm());
        } catch (e) {
            let msg = e.message;
            dispatch(createOrderHeaderFailed(msg));
            dispatch(showToast(resources.FAILED, msg,'red'));
        }
	}
}



function patchOrderHeaderStarted() {
    return { type: PATCH_ORDERHEADER_STARTED  }
}
function patchOrderHeaderSucceeded(id, orderheader) {
    return { type: PATCH_ORDERHEADER_SUCCESS, payload:{id, orderheader}  }
}
function patchOrderHeaderFailed(error) {
    return { type: PATCH_ORDERHEADER_FAILED, payload: {error} }
}
/**
 * Patch orderheader
 * @param {*} orderheader : object 
 */
export function patchOrderHeader(id, orderheaderPatch) {
   
	return async dispatch => { 
        try { 
            dispatch(patchOrderHeaderStarted());
            const data = await new ApiCall().patch(ORDERHEADER_URL, id, orderheaderPatch);
            dispatch(patchOrderHeaderSucceeded(id, data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideOrderHeaderForm());
        } catch (e) {
            let msg = e.message;
            dispatch(patchOrderHeaderFailed(msg));
            dispatch(showToast(resources.FAILED, msg,'red'));
        }
	}
}



function updateOrderHeaderStateStarted() {
    return { type: UPDATE_ORDERHEADER_STATE_STARTED  }
}
function updateOrderHeaderStateSucceeded(id,orderStatus) {
    return { type: UPDATE_ORDERHEADER_STATE_SUCCESS, payload: {id,orderStatus}  }
}
function updateOrderHeaderStateFailed(error) {
    return { type: UPDATE_ORDERHEADER_STATE_FAILED, payload: {error} }
}
/**
 * Update orderheader by id
 * @param {*} id 
 */
export function updateOrderHeaderState(id,orderStatus) {
    
	return async dispatch => { 
        try { 
            dispatch(updateOrderHeaderStateStarted())
          
            let updateUrl = ORDERHEADER_URL;
            if(updateUrl.endsWith("/") === false)
            updateUrl += "/";

            updateUrl += id + "/" + orderStatus + "/"
            await new ApiCall().call(httpMethods.PUT,updateUrl,null);
  
            dispatch(updateOrderHeaderStateSucceeded(id,orderStatus));
            dispatch(showToast(resources.SAVED, '','green'));
        } catch (e) {
            dispatch(updateOrderHeaderStateFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}




function updateOrderHeaderStarted() {
    return { type: UPDATE_ORDERHEADER_STARTED  }
}
function updateOrderHeaderSucceeded(id,orderheader) {
    return { type: UPDATE_ORDERHEADER_SUCCESS, payload: {id,orderheader}  }
}
function updateOrderHeaderFailed(error) {
    return { type: UPDATE_ORDERHEADER_FAILED, payload: {error} }
}
/**
 * Update orderheader by id
 * @param {*} id 
 */
export function updateOrderHeader(id,orderheader) {
    
	return async dispatch => { 
        try { 
            dispatch(updateOrderHeaderStarted())
            let rows = [...orderheader.rows];
            delete orderheader.rows;
            const data = await new ApiCall().update(ORDERHEADER_URL, id, orderheader);
            data.rows = rows;
            dispatch(updateOrderHeaderSucceeded(id,data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideOrderHeaderForm());
        } catch (e) {
            dispatch(updateOrderHeaderFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}



function deleteOrderHeaderStarted() {
    return { type: DELETE_ORDERHEADER_STARTED  }
}
function deleteOrderHeaderSucceeded(id) {
    return { type: DELETE_ORDERHEADER_SUCCESS, payload: {id}  }
}
function deleteOrderHeaderFailed(error) {
    return { type: DELETE_ORDERHEADER_FAILED, payload: {error} }
}
/**
 * Delete by id
 * @param {*} id 
 */
export function deleteOrderHeader(id) {
    
	return async dispatch => { 
        try { 
            dispatch(deleteOrderHeaderStarted())
            await new ApiCall().delete(ORDERHEADER_URL, id);
            dispatch(deleteOrderHeaderSucceeded(id));
            dispatch(showToast(resources.DELETED, '','green'));
            dispatch(hideOrderHeaderForm());
        } catch (e) {
            dispatch(deleteOrderHeaderFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}
