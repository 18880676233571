import resources from "../../../resources";

export const OrderType = {
    FromList:0,
    Empty: 1
}
/**
 * Translate ordertype to text
 * @param {*} ordertype 
 */
export const getOrderTypeText = (ordertype) => {
    
    switch(ordertype){
        case OrderType.FromList:
            return resources.LIST;
        case OrderType.Empty:
            return resources.EMPTY;
        default:
            return OrderType.toString();
    }
}
/**
 * Translate ordertype to options
 * @param {*} ordertype 
 */
export const getOrderTypeDropdownOptions = () => {

    let options = [];
    for (var property in OrderType) 
        options.push({
            key: 'ordertype-' + property,
            text: getOrderTypeText(OrderType[property]),
            value: OrderType[property]
        });

    return options;
   
}