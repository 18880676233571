
import { 
    FETCH_SETTINGS_STARTED, FETCH_SETTINGS_FAILED, FETCH_SETTINGS_SUCCESS,
    CREATE_SETTING_STARTED,CREATE_SETTING_SUCCESS,CREATE_SETTING_FAILED,
    SHOW_SETTING_FORM, HIDE_SETTING_FORM } from '../actions/setting';

    
/**
 * initialstate
 */
const initialState = {
    loading:false,
    settings: {},
    error:'',
    showform:false
};

/**
 * reducer
 * @param {*} state 
 * @param {*} action 
 */
export default function setting(state = initialState, action) {
  switch (action.type) {
    case SHOW_SETTING_FORM:
        return {
            ...state,
            showform:true
        };
    
    case HIDE_SETTING_FORM:
        return {
            ...state,
            showform:false
        };
    
    case FETCH_SETTINGS_STARTED:
        return {
            ...state,
            loading:true
        };
    case FETCH_SETTINGS_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case FETCH_SETTINGS_SUCCESS:
        return {
            ...state,
            loading:false,
            settings:{...action.payload.settings}
        };
        
    case CREATE_SETTING_STARTED:
      return {
          ...state,
          loading:true
      };
    case CREATE_SETTING_FAILED:
        return {
            ...state,
            loading:false,
            error:action.payload.error
        };
    case CREATE_SETTING_SUCCESS:
        return {
            ...state,
            loading:false,
            settings:{...action.payload.settings}
        };

    default:
      return state;
  }
}
/***
 * Selector to get importsettins
 */
export function getImportSettings(settingObj) { 

    if(settingObj === null || settingObj === undefined)
        return {};

    let importSettings = {};

    if(settingObj[OrderImportColumns.Category] && settingObj[OrderImportColumns.Category] !== "") importSettings[OrderImportColumns.Category] = window.parseInt(settingObj[OrderImportColumns.Category],10);
    if(settingObj[OrderImportColumns.CostPlace] && settingObj[OrderImportColumns.CostPlace] !== "") importSettings[OrderImportColumns.CostPlace] = window.parseInt(settingObj[OrderImportColumns.CostPlace],10);
    if(settingObj[OrderImportColumns.Room] && settingObj[OrderImportColumns.Room] !== "") importSettings[OrderImportColumns.Room] = window.parseInt(settingObj[OrderImportColumns.Room],10);
    if(settingObj[OrderImportColumns.Place] && settingObj[OrderImportColumns.Place] !== "") importSettings[OrderImportColumns.Place] = window.parseInt(settingObj[OrderImportColumns.Place],10);
    if(settingObj[OrderImportColumns.ProductCode] && settingObj[OrderImportColumns.ProductCode] !== "") importSettings[OrderImportColumns.ProductCode] = window.parseInt(settingObj[OrderImportColumns.ProductCode],10);
    if(settingObj[OrderImportColumns.ProductName] && settingObj[OrderImportColumns.property] !== "") importSettings[OrderImportColumns.ProductName] = window.parseInt(settingObj[OrderImportColumns.ProductName],10);
    if(settingObj[OrderImportColumns.StorageQuantity] && settingObj[OrderImportColumns.StorageQuantity] !== "") importSettings[OrderImportColumns.StorageQuantity] = window.parseInt(settingObj[OrderImportColumns.StorageQuantity],10);
    if(settingObj[OrderImportColumns.SalesUnit] && settingObj[OrderImportColumns.SalesUnit] !== "") importSettings[OrderImportColumns.SalesUnit] = window.parseInt(settingObj[OrderImportColumns.SalesUnit],10);
    if(settingObj[OrderImportColumns.StorageUnit] && settingObj[OrderImportColumns.StorageUnit] !== "") importSettings[OrderImportColumns.StorageUnit] = window.parseInt(settingObj[OrderImportColumns.StorageUnit],10);
    if(settingObj[OrderImportColumns.Created] && settingObj[OrderImportColumns.Created] !== "") importSettings[OrderImportColumns.Created] = window.parseInt(settingObj[OrderImportColumns.Created],10);

    if(settingObj[OrderImportColumns.ProductNameExtra] && settingObj[OrderImportColumns.ProductNameExtra] !== "") importSettings[OrderImportColumns.ProductNameExtra] = window.parseInt(settingObj[OrderImportColumns.ProductNameExtra],10);
    if(settingObj[OrderImportColumns.VendorProductCode] && settingObj[OrderImportColumns.VendorProductCode] !== "") importSettings[OrderImportColumns.VendorProductCode] = window.parseInt(settingObj[OrderImportColumns.VendorProductCode],10);
    if(settingObj[OrderImportColumns.SalesPrice] && settingObj[OrderImportColumns.SalesPrice] !== "") importSettings[OrderImportColumns.SalesPrice] = window.parseInt(settingObj[OrderImportColumns.SalesPrice],10);
    if(settingObj[OrderImportColumns.StoragePrice] && settingObj[OrderImportColumns.StoragePrice] !== "") importSettings[OrderImportColumns.StoragePrice] = window.parseInt(settingObj[OrderImportColumns.StoragePrice],10);
     
    return importSettings;
}


export const OrderImportColumns = {
    Category:"Category",
    CostPlace:"CostPlace",
    Room:"Room",
    Place:"Place",
    ProductCode:"ProductCode",
    ProductName:"ProductName",
    StorageQuantity:"StorageQuantity",
    SalesUnit:"SalesUnit",
    StorageUnit:"StorageUnit",
    Created:"Created",
    ProductNameExtra:"ProductNameExtra",
    VendorProductCode:"VendorProductCode",
    SalesPrice:"SalesPrice",
    StoragePrice:"StoragePrice"
}