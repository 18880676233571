

import ApiCall from '../../../api/ApiCall';
import history from '../../../common/history';
import resources from '../../../resources';
import { showToast } from './../../../toast/actions/toast';
import configuration from './../../../configuration';
import moment from 'moment';
import { fetchSettings } from '../../orders/actions/setting';

export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGIN_URL = configuration.AUTH_URL;
export const LOGOUT_URL = configuration.AUTH_URL + '/logout';
export const DISABLED_ROLE = 'Disabled';


function logoutStarted() {
    return { type: LOGOUT_STARTED } 
}
function logoutSucceeded() {
    return { type: LOGOUT_SUCCESS }
}
function logoutFailed(err) {
    return { type: LOGOUT_FAILURE, payload: err } 
}
/**
 * Logout
 */
export function logout() {

    return async (dispatch, getState) => { 
        try { 
            dispatch(logoutStarted())
            //Redirect to login, if not allready
            if(getState().router.location.pathname !== configuration.routes.login.route)
                history.push(configuration.routes.login.route);
                
            const data = await new ApiCall().get(LOGOUT_URL);
            window.localStorage.removeItem(configuration.localStorageKey);

            dispatch(logoutSucceeded(data));
            

        } catch (e) {
            dispatch(logoutFailed(e.message));
        }
	}
}
function loginStarted() {
    return { type: LOGIN_STARTED }
}
function loginSucceeded(user) {
    return { type: LOGIN_SUCCESS, payload: { user } }
}
function loginFailed(err) {
    return { type: LOGIN_FAILURE, payload: err }
}
/**
 * Login
 * @param {*} query 
 */
export function login(query, redirecto) {
	return async (dispatch, getState) => { 
        try { 
            dispatch(loginStarted());
            const data = await new ApiCall().create(LOGIN_URL, query);
            //Check if has a role that is allowed

            //If users role is not in allowed list
            if(configuration.ALLOWED_ROLES.filter(value => -1 !== data.Roles.indexOf(value)).length === 0)
            {
                //User is disabled, logout
                dispatch(showToast(resources.LOGIN_FAILED,'','red'));
                dispatch(loginFailed(resources.LOGIN_FAILED));
                console.log("Logging out, users role is not in allowed list");
                dispatch(logout());
            }
            else {
                dispatch(loginSucceeded(data));
                    
                //This is a hack for identity
                let identityData = {
                    user: data,
                    token: { ValidTo: moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'), Value: '' }
                };
                window.localStorage.setItem(configuration.localStorageKey,window.JSON.stringify(identityData));
                //Redirect if not allready
                //Check user role, redirect to allowed route

                dispatch(fetchSettings());

                let allowedRoutesNotLogin = [];
                //Fill with users routes, NOT loginroute
                for (let key in configuration.routes) {
                    let routeconfig = configuration.routes[key];
                    
                    //If not loginroute, filter it out
                    //If users routes are also in route roles
                    if(routeconfig.route !== configuration.routes.login.route && 
                        routeconfig.roles.filter(value => -1 !== data.Roles.indexOf(value)).length > 0){
                            allowedRoutesNotLogin.push(routeconfig);
                    }
                }


                //Redirect user to a route, if no route then get first allowed route that is not login
                let redirectRoute = redirecto ? redirecto : allowedRoutesNotLogin[0].route;

                if(redirecto) {
                    redirectRoute = redirecto;
                }

                if(redirectRoute && getState().router.location.pathname !== redirectRoute)
                    history.push(redirectRoute);
                
            }
        } catch (e) {
            dispatch(loginFailed(e.message))
            let message= e.message.startsWith('401') || e.message.startsWith('403') ? '' : e.message;
            dispatch(showToast(resources.LOGIN_FAILED, message,'red'));
        }
	}
}
