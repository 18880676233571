import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import configuration from '../../../configuration';
import { logout, login } from '../actions/login';
import history from '../../../common/history';



class LoginCheck extends Component {
    constructor(props){
        super(props);
        this.loginInterval = null;
    }
    /***
     * Setup automatic relogin => refresh token automatically in interval
     * Setup route change listener, if new route that is not login then set  auto login interval
     */
    componentDidMount(){
        const { pathname } = this.props;
        
        if(window.localStorage.getItem(configuration.localStorageKey) === null && pathname !== configuration.routes.login.route)  
            history.push(configuration.routes.login.route); //Move to login
    
        
        //On first load
        this.setupAutomaticRelogin(true);
        history.listen((location,action) => {
            //When url changes
            if(history && history.location && history.location.pathname !== configuration.routes.login.route){
                this.setupAutomaticRelogin();
            }
        });

    }
   /***
   * Setup automatic refresh of token, so that login will persist
   */
  setupAutomaticRelogin = (firstlogin) => {
        const { pathname } = this.props;
        //Clear any current login intervals, if exists
        if(this.loginInterval && this.loginInterval !== null) window.clearInterval(this.loginInterval);

        //If current page is not "/login" page, then try to login
        if(pathname !== configuration.routes.login.route){
            if(firstlogin) this.login(); //Relogin 

            //Setup interval to relogin, so that the token will update itself
            this.loginInterval = window.setInterval(() => {
                this.login()
            }, 3600000); // : Relogin every 60 minutes automatic

        }
    }
    isallowedRoute(pathname,user){

        if(user === null || user === undefined)
            return false;

        //Fill with users routes, NOT loginroute
        for (let key in configuration.routes) {
            let routeconfig = configuration.routes[key];
            if(routeconfig.route === pathname){
                //Found the routeconfig
                //Check if user has access
                if(routeconfig.roles.filter(value => -1 !== user.Roles.indexOf(value)).length > 0){
                    return true;
                }
                else 
                    return false;
            }
        }

        return false;
    }
    componentDidUpdate(prevProps) {
        const { user, pathname, loading } = this.props;

        
        //If not allowed route, then logout
        if(!loading && user !== null && user !== undefined && this.isallowedRoute(pathname,user) === false && pathname !== configuration.routes.login.route)
        {
            console.log("Logging out, pathname=" + pathname + " is not allowed for user");
            this.props.dispatch(logout());
        }
        
    }
    login = () => {
        //Try to relogin, session could be in httponly cookie, but current user is not saved on reload, get it from server
        this.props.dispatch(login(undefined,history.location.pathname));
    }
   render(){
        return (null);
   }
}
LoginCheck.propTypes = {
    dispatch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool.isRequired
  };
  
  function mapStateToProps(state) {
    return { 
        pathname: state.router.location.pathname,
        user: state.login.user,
        loading: state.login.loading
      };
  }
  
  export default connect(mapStateToProps)(LoginCheck);
