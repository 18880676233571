import configuration from "./configuration";

const resources = {
    fi: {
      NO_MATCH:'Etsimääsi sivua ei löytynyt',
      LOGIN_TITLE:'Sisäänkirjautuminen',
      ORDERHEADER_TITLE:'Tilaukset',
      LOGS_TITLE:'Lokit',
      LOGOUT:'Ulos',
      MENU:'Valikko',
      LOGIN_FAILED:'Kirjautuminen epäonnistui',
      LOGOUT_FAILED:'Uloskirjautuminen epäonnistui',
      STARTED:'Aloitettu',
      FAILED:'Epäonnistui',
      EMPLOYEE:'Työntekijä',
      SEARCH:'Hae',
      TO_CSV:'Vie csv',
      ERROR:'Virhe',
      STARTING:'Alkaen',
      ENDING:'Päättyen',
      CREATED:'Luotu',
      TITLE:'Otsikko',
      MESSAGE:'Viesti',
      LOGLEVEL:'Lokitaso',
      USER:'Käyttäjä',
      CLIENT_ID:'Asiakastunnus',
      EVENT:'Tapahtuma',
      SOURCE:'Lähde',
      INFO:'Info',
      WARNING:'Varoitus',
      CANCEL:'Peruuta',
      CREATE_NEW:'Luo uusi',
      EDIT: 'Muokkaa',
      DELETE_OLD: 'Poista vanhat',
      VIEW:'Näytä',
      TRACE:'Trace',
      DEBUG:'Debug',
      CRITICAL:'Kriittinen',
      NONE:'Ei mitään',
      DELETED:'Poistettu',
      TAG:'Tunniste',
      NAME:'Nimi',
      SAVE:'Tallenna',
      EDITED:'Muokattu',
      ARE_YOU_SURE:'Oletko varma?',
      SAVED:'Tallennettu',
      DESCRIPTION:'Kuvaus',
      START_TIMEOUT:'Aloitus sek',
      END_TIMEOUT:'Lopetus sek',
      MINWORK_TIMEOUT:'Min työaika',
      EMPLOYEE_NUMBER:'Työntekijänumero',
      READER_IP : 'Lukijan ip',
      ONLY_CSV_FILE: 'Vain .csv tiedosto on tuettu',
      IMPORT_CSV:'Tuo csv',
      NO_ROWSDATA:'Ei rividataa',
      DATA_IN_WRONG_FORMAT: 'Data on väärässä formaatissa',
      SOME_COLUMN_IS_EMPTY_FIX:'Jokin sarakkeista on tyhjä, tarkasta rivi',
      VALUE_IS_IN_WRONG_FORMAT:'Arvo on väärässä muodossa',
      UI_VIEW:'Näkymä',
      CARDS:'Kortit',
      TABLE:'Taulukko',
      USERNAME:'Käyttäjänimi',
      PASSWORD: 'Salasana',
      LOGIN:'Kirjaudu',
      PRODUCT_CODE: 'Tuotekoodi',
      PRODUCT_EXTRA: 'Lisätiedot',
      VENDOR: 'Toimittaja',
      VENDOR_PRODUCT_CODE: 'Toimittajakoodi',
      SALESUNIT:'Myyntiyksikkö',
      STORAGEUNIT:'Varastoyksikkö',
      SALESPRICE:'á hinta',
      STORAGEPRICE:'á varastohinta',
      MINIMUM_ORDERAMOUNT:'Min tilausmäärä',
      UPDATED: 'Muokattu',
      CATEGORY: 'Kategoria',
      COST_PLACE: 'Kustannuspaikka',
      ORDERLIST_NUMBER:'Tilausnumero',
      ROOM:'Huone',
      SHELF_LOCATION:'Hyllypaikka',
      STATE:'Tila',
      CREATED_BY: 'Luonut',
      MODIFIED_BY:'Muokannut',
      STORAGE_QUANTITY:'Varastosaldo',
      ORDER_QUANTITY:'Tilausmäärä',
      IN_PROGRESS:'Työn alla',
      ACCEPTED:'Hyväksytty',
      REJECTED:'Hylätty',
      DELETE : 'Poista',
      OPEN: 'Avaa',
      ORDERROW_TITLE:'Tilausrivit',
      ORDER:'Tilaus',
      GO_BACK:'Palaa takaisin',
      ACCEPT_ORDER:'Hyväksy tilaus',
      REJECT_ORDER:'Hylkää tilaus',
      CREATE_NEW_ROW:'Luo uusi rivi',
      DONE:'Valmis',
      LIST:'Lista',
      EMPTY:'Tyhjä',
      TYPE:'Tyyppi',
      PRICE:'Hinta',
      TOO_LONG_COSTPLACE:'Liian pitkä kustannuspaikka',
      UNIT:'Yksikkö',
      STORAGEUNIT_SHORT:'Varasto',
      SALESUNIT_SHORT:'Myynti'
    },
    en: {
      NO_MATCH:'The page does not exist',
      LOGIN_TITLE:'Login',
      ORDERHEADER_TITLE:'Orders',
      LOGS_TITLE:'Logs',
      LOGOUT:'Logout',
      MENU:'Menu',
      LOGIN_FAILED:'Login failed',
      LOGOUT_FAILED:'Logout failed',
      STARTED:'Started',
      FAILED:'Failed',
      EMPLOYEE:'Employee',
      SEARCH:'Search',
      TO_CSV:'To csv',
      ERROR:'Error',
      STARTING:'Starting',
      ENDING:'Ending',
      CREATED:'Created',
      TITLE:'Title',
      MESSAGE:'Message',
      LOGLEVEL:'Loglevel',
      USER:'User',
      CLIENT_ID:'Client ID',
      EVENT:'Event',
      SOURCE:'Source',
      INFO:'Info',
      WARNING:'Warning',
      CANCEL:'Cancel',
      CREATE_NEW:'Create new',
      EDIT: 'Edit',
      DELETE_OLD: 'Delete old',
      VIEW:'View',
      TRACE:'Trace',
      DEBUG:'Debug',
      CRITICAL:'Critical',
      NONE:'None',
      DELETED:'Deleted',
      TAG:'Tag',
      NAME: 'Name',
      SAVE:'Save',
      EDITED:'Edited',
      ARE_YOU_SURE:'Are you sure?',
      SAVED:'Saved',
      DESCRIPTION:'Description',
      START_TIMEOUT:'Start timeout sec',
      END_TIMEOUT:'End timeout sec',
      MINWORK_TIMEOUT:'Min work dur',
      EMPLOYEE_NUMBER:'Employee number',
      READER_IP : 'Reader ip',
      ONLY_CSV_FILE: 'Only .csv is supported',
      IMPORT_CSV: 'Import csv',
      NO_ROWSDATA:'No rows in data',
      DATA_IN_WRONG_FORMAT: 'Data is in wrong format',
      SOME_COLUMN_IS_EMPTY_FIX:'Some column is empty, check row',
      VALUE_IS_IN_WRONG_FORMAT:'Value is in wrong format',
      UI_VIEW:'View',
      CARDS:'Cards',
      TABLE:'Table',
      USERNAME:'Username',
      PASSWORD: 'Password',
      LOGIN:'Login',
      PRODUCT_CODE: 'Product code',
      PRODUCT_EXTRA: 'Extra',
      VENDOR: 'Vendor',
      VENDOR_PRODUCT_CODE: 'Vendor code',
      SALESUNIT:'Sales unit',
      STORAGEUNIT:'Storage unit',
      SALESPRICE:'á price',
      STORAGEPRICE:'á storageprice',
      MINIMUM_ORDERAMOUNT:'Min order',
      UPDATED: 'Modified',
      CATEGORY:'Category',
      COST_PLACE:'Cost place',
      ORDERLIST_NUMBER:'Order number',
      ROOM:'Room',
      SHELF_LOCATION:'Shelf',
      STATE:'State',
      CREATED_BY: 'Created by',
      MODIFIED_BY: 'Modified by',
      STORAGE_QUANTITY:'Storage quantity',
      ORDER_QUANTITY:'Order quantity',
      IN_PROGRESS:'In Progress',
      ACCEPTED:'Accepted',
      REJECTED:'Rejected',
      DELETE : 'Delete',
      OPEN: 'Open',
      ORDERROW_TITLE:'Orderrows',
      ORDER:'Order',
      GO_BACK:'Go back',
      ACCEPT_ORDER:'Accept order',
      REJECT_ORDER:'Reject order',
      CREATE_NEW_ROW:'Create new row',
      DONE:'Done',
      LIST:'List',
      EMPTY:'Empty',
      TYPE:'Type',
      PRICE: 'Price',
      TOO_LONG_COSTPLACE:'Too long costplace',
      UNIT:'Unit',
      STORAGEUNIT_SHORT:'Storage',
      SALESUNIT_SHORT:'Sales'
    }
  };
  
  /* if freeze is supported */
  if (typeof Object !== "undefined" && !!Object.freeze) {
    Object.freeze(resources);
  }
  
  export default resources[configuration.lang];
  