import resources from "../../../resources";

export const loglevels = {
    Trace:0,
    Debug:1,
    Info:2,
    Warning:3,
    Error:4,
    Critical:5,
    None:6
}
/**
 * Translate loglevel to text
 * @param {*} loglevel 
 */
export const getLogLevelText = (loglevel) => {
    switch(loglevel){
        case loglevels.Trace:
            return resources.TRACE;
        case loglevels.Debug:
            return resources.DEBUG;
        case loglevels.Info:
            return resources.INFO;
        case loglevels.Warning:
            return resources.WARNING;
        case loglevels.Error:
            return resources.ERROR;
        case loglevels.Critical:
            return resources.CRITICAL;
        case loglevels.None:
            return resources.NONE;
        default:
            return loglevel.toString();
    }
}