import React, { Component }  from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import configuration from '../../configuration';
import { Route, Switch } from 'react-router'
import Container from 'semantic-ui-react/dist/commonjs/elements/Container/Container';
import Logs from '../../pages/logview/Logs';
import NoMatch from './../../pages/NoMatch';
import Login from './../../pages/login/Login';
import Orders from './../../pages/orders/Orders';


class UserRoutes extends Component {

    getComponentForRoute(route){
        switch(route){
            case configuration.routes.login.route:
                return Login;
            case configuration.routes.orders.route:
                return Orders;
            case configuration.routes.logs.route:
                return Logs;
            default:
                return NoMatch;
        }
    }
    getUsersRoutes(){
        const { user } = this.props;
   
        
        //Fill with all routes initially
        let routes = [];
        for (let key in configuration.routes) {
            routes.push(configuration.routes[key]);
        }

        if(user){

            //Clear routes
            routes = [];

            //Fill with users routes
            for (let key in configuration.routes) {
                let routeconfig = configuration.routes[key];
                
                //If users routes are also in route roles
                if(routeconfig.roles.filter(value => -1 !== user.Roles.indexOf(value)).length > 0){
                    routes.push(routeconfig);
                }
            }

            
            // return <Switch>
            //         <Route exact path={configuration.routes.tickets.route} component={Tickets} />
            //         <Route exact path={configuration.routes.login.route} component={Login} />
            //         <Route exact path={configuration.routes.rides.route} component={Rides} />
            //         <Route exact path={configuration.routes.usage.route} component={Usages} />
            //         <Route exact path={configuration.routes.users.route} component={Users} />
            //         <Route exact path={configuration.routes.open.route} component={OpenHours} />
            //         <Route exact path={configuration.routes.logs.route} component={Logs} />
            //         <Route component={NoMatch} />
            //     </Switch>; 
        }
        
        
        return <Switch>
                    {routes.map((item) => <Route exact key={'route-' + item.route} path={item.route} component={this.getComponentForRoute(item.route)} />)};
                </Switch>; 
    }
    render(){
            return (<Container className='main'>
                        {this.getUsersRoutes()}
                    </Container>);
    }
}
UserRoutes.propTypes = {
    dispatch: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return { 
            pathname: state.router.location.pathname,
            user: state.login.user,
            loading: state.login.loading
        };
}

export default connect(mapStateToProps)(UserRoutes);