import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import configuration from '../configuration';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import { logout } from '../pages/login/actions/login';
import resources from './../resources';




class MainMenu extends Component {

    constructor(props) {
        super(props);
        document.title = this.getDocumentTitle();
    }
   
    componentDidUpdate(prevProps) {
        document.title = this.getDocumentTitle();
    }
   
    getDocumentTitle() {
        const { pathname } = this.props;
        if (pathname === configuration.routes.login.route) {
            return resources.LOGIN_TITLE;
        }
        else if (pathname.startsWith(configuration.routes.orders.route) || pathname.startsWith(configuration.routes.orders.route)) {
            return resources.ORDERHEADER_TITLE;
        }
        else if (pathname.startsWith(configuration.routes.logs.route)) {
            return resources.LOGS_TITLE;
        }

    }
    redirectTo = (path, always) =>{
        const { pathname } = this.props;
        if (path !== pathname) 
            this.props.history.push(path);
    }
    logout = () => {
        if(this.props.loading === false)
            this.props.logout();
    }
    isAllowed = (routeconfig) => {
        const { user } = this.props;
        return user !== undefined && user !== null && routeconfig.roles.filter(value => -1 !== user.Roles.indexOf(value)).length > 0;
    }
    render() {
        const { pathname, loading } = this.props;
        
        return (
            (pathname === configuration.routes.login.route ) ? ('') : (
            <React.Fragment><Menu fixed='top'>
                <Container>
                    {this.isAllowed(configuration.routes.orders) &&
                    <Menu.Item active={pathname === configuration.routes.orders.route || pathname === configuration.routes.orders.route} 
                                onClick={() => this.redirectTo(configuration.routes.orders.route)} 
                                title={resources.ORDERHEADER_TITLE}>
                        <Icon name='list' /> <div className='mobile hidden'>{resources.ORDERHEADER_TITLE}</div>
                    </Menu.Item>}
                    
                    {(this.isAllowed(configuration.routes.logs)) && 
                    <Dropdown item simple text={resources.MENU}>
                        <Dropdown.Menu>
                            {this.isAllowed(configuration.routes.logs) &&
                            <Menu.Item active={pathname === configuration.routes.logs.route} onClick={() => this.redirectTo(configuration.routes.logs.route)}>
                                <Icon name='bug' /> {resources.LOGS_TITLE}
                            </Menu.Item>}
                        </Dropdown.Menu>
                    </Dropdown>}


                    <Menu.Item position='right' active={pathname === configuration.routes.login.route} onClick={this.logout}>
                        <Icon name='sign out' /> <div className='mobile hidden'>{loading ? (<Loader active size='mini' />):(resources.LOGOUT)}</div>
                    </Menu.Item>
                </Container>
            </Menu><div className='menu-spacer'/></React.Fragment>)
        );
    }
}
MainMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    loading: PropTypes.bool
  };
  const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    }
  }
  function mapStateToProps(state) {
    return { 
        pathname: state.router.location.pathname,
        user: state.login.user,
        loading: state.login.loading
      };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(MainMenu);

