
import { constructOrderHeaderTableHeaders,constructOrderHeaderTableRows } from '../components/OrderHeaderTable';
import { constructQueryUrl } from '../../../api/ApiCall';
import ApiCall from '../../../api/ApiCall';
import Papa from 'papaparse/papaparse.min.js';
import resources from '../../../resources';
import { OrderImportColumns } from './OrderImportColumns';
import moment from 'moment';
import { ORDERHEADER_URL } from '../actions/orderheader';
import { ORDERROW_URL } from '../actions/orderrow';
import { constructOrderRowTableHeaders } from '../components/OrderRowTable';
import { constructOrderRowTableRows } from '../components/OrderRowTable';
import OrderHeader from '../models/OrderHeader';
import { OrderState } from './OrderState';
import OrderRow from '../models/OrderRow';
import { OrderType } from './OrderType';


class OrderCsvParser {

    
    /**
     * Parse csv file to data, 
     * success - Called when data is ready and valid
     * failed - Called on failure, and if data is not valid
     */
    parseCsv = (username, importsettings, file, success, failed) => {

        //Use settimeout, so ui has some time to re render
        window.setTimeout(() => {

            Papa.parse(file, {
                //header: true, => if true, then returns object with same properties as header, if false returns array
                download: true,
                skipEmptyLines: true,
                delimiter: ';',
                complete: (results, file) => { 
                    let data = [...results.data];
                    //Validate data
                    let result = this.validateAndParseCsv(data, importsettings);
                    if (result.validateError !== '') {
                        failed(result.validateError)
                    }
                    else {
                        success(this.convertCsvDataToOrders(username, importsettings, result.parseData));
                    }
                
                },
                error: (error,file) => failed(error.toString()),
                // step: this.setParseStep,
                fastMode: true
            });
        }, 10);
    }
    /**
     * Convert csv data to orders
     */
    convertCsvDataToOrders = (username,importsettings, data) => {

        return new OrderHeader(0, 
                moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                null,
                username,
                null,
                data.length > 0 ? data[0][OrderImportColumns.CostPlace] : '',
                data.length > 0 ? data[0][OrderImportColumns.Room] : '',
                OrderState.Created,
                OrderType.FromList,
                data.map((item,index) => {
                    return new OrderRow(0, 
                                0,
                                item[OrderImportColumns.Created],
                                //moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                                null,
                                username,
                                null,
                                item[OrderImportColumns.Category],
                                item[OrderImportColumns.Place],
                                item[OrderImportColumns.ProductCode],
                                item[OrderImportColumns.ProductName],
                                item[OrderImportColumns.StorageQuantity],
                                item[OrderImportColumns.SalesUnit],
                                item[OrderImportColumns.StorageUnit],
                                OrderState.Created
                            );
                })
            );

    }
     /***
     * Validate csv
     */
    validateAndParseCsv = (data, importsettings) => {

        let validateError = '';
        let parseData = [...data];
        //Remove header row
        parseData.splice(0,1);

        if (data.length === 0) {
            validateError = resources.NO_ROWSDATA;
            return {validateError,parseData};
        }

        let indexFix = 2; //Headerrow + starting from 1

        if (typeof data !== 'object') {
            validateError = resources.DATA_IN_WRONG_FORMAT;
            return {validateError,parseData};
        }
        //End to 0, skip header
        for (let index = parseData.length-1; index > -1 ; index--) {
            let element = parseData[index];

            // IF element array does not have 10 columns or if all columns are empty, skip
            if(element.length < Object.keys(importsettings).length || element.every(i => i === undefined || i === "" || i === null)){
                parseData.splice(index,1);
            }
            else if(element[importsettings.CostPlace] === undefined || element[importsettings.CostPlace].trim() === ''){
                validateError = resources.SOME_COLUMN_IS_EMPTY_FIX + ': ' + (index+indexFix) + ' [ ' + resources.COST_PLACE + ': ' + element[importsettings.CostPlace] + ']';
                break;
            }
            else if(element[importsettings.CostPlace] && element[importsettings.CostPlace].length > 250){
                validateError = resources.TOO_LONG_COSTPLACE + ': (' + resources.ROW + " " + (index+indexFix) + ') [ ' + resources.COST_PLACE + ': ' + element[importsettings.CostPlace] + ']';
                break;
            }
            else if(element[importsettings.ProductCode] === undefined || element[importsettings.ProductCode].trim() === ''){
                validateError = resources.SOME_COLUMN_IS_EMPTY_FIX + ': ' + (index+indexFix) + ' [ ' + resources.PRODUCT_CODE + ': ' + element[importsettings.ProductCode] + ']';
                break;
            }
            else {
                if(element[importsettings.Room] && element[importsettings.Room].length > 250)
                    parseData[index][importsettings.Room] = element[importsettings.Room].substring(0,250);
                //Varastosaldo as number
                parseData[index][importsettings.StorageQuantity] = this.parseAsFloat(parseData[index][importsettings.StorageQuantity]);
                //Created päiväysimport { OrderType } from './OrderType';

                parseData[index][OrderImportColumns.Created] = moment(parseData[index][OrderImportColumns.Created]).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
                
            }

        }
        return {validateError,parseData};
  }
  /**
   * Parse string value to float
   */
  parseAsFloat = (val) => {
    
    if(val === undefined || val === null || val === "" || val.toString().trim() === "" || window.isNaN(val.toString().replace(",","."))) return 0;

    let parsedValue = window.parseFloat(val.toString().replace(",","."));

    if(window.isNaN(parsedValue)) return 0;

    return parsedValue;

  }
  /**
   * Get OrderHeaders csv
   */
  getHeadersCsv = async (query) => {
    return this.getCsv(query,ORDERHEADER_URL,constructOrderHeaderTableHeaders,constructOrderHeaderTableRows);
  }
  /**
   * Get OrderRows csv
   */
  getRowsCsv = async (query) => {
    return this.getCsv(query,ORDERROW_URL, constructOrderRowTableHeaders, constructOrderRowTableRows);
  }
  /***
   * Create csv from resultset,
   * - Load all data in database for this query
   * - Convert headers for csv from current table in view
   * - Convert table data rows for csv from current table in view
   */
  getCsv = async (query, url, constructHeaders, constructRows) => {

        //Get data
        let data = await new ApiCall(true).get(constructQueryUrl(url, query));
        
        return this.getCsvBydata(data,constructHeaders,constructRows);
  }
  /**
   * Return csv data
   * - Convert headers for csv from current table in view
   * - Convert table data rows for csv from current table in view
   */
  getCsvBydata = (data, constructHeaders, constructRows) => {
        
      //Get headers for table and convert
      let headers = constructHeaders().filter(i => i.name).map((item) => {
          return { 
              label: item.value && item.value !== null ? item.value.replace(/;/g, ':').replace(/(?:\r\n|\r|\n)/g, '') : '', 
              key: item.name && item.name !== null ? item.name.replace(/;/g, ':').replace(/(?:\r\n|\r|\n)/g, '') : '', 
              csv: item.csv };
      });
      let csvdata = [];
      //Get rows for table and convert
      let tableRows = constructRows(data)
   
      for(let x = 0; x < tableRows.length; x++){
          let row = {};
          for(let y = 0; y < headers.length; y++){
            if(headers[y].csv !== false){
                row[headers[y].key] = tableRows[x].cells[y].csv !== undefined ? tableRows[x].cells[y].csv !== null ? tableRows[x].cells[y].csv.replace(/;/g, ':').replace(/(?:\r\n|\r|\n)/g, '') : '' : tableRows[x].cells[y].value !== null ? tableRows[x].cells[y].value.replace(/;/g, ':').replace(/(?:\r\n|\r|\n)/g, '') : '';
            }
          }
          csvdata.push(row);
      }
      headers = headers.filter(i => i.csv !== false);

      return { data: csvdata, headers:headers }
  }
}

export default OrderCsvParser