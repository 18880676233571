import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
import logo from './img/logo.svg';
import LoginForm from './components/LoginForm';
import { login } from './actions/login';

class Login extends Component {
  constructor(props) {
      super(props);
      this.submitLogin = this.submitLogin.bind(this);
  }

  submitLogin(username,password){
    this.props.dispatch(login({username,password}));
  }
  render() {
    let innerHeight = window.innerHeight - 50;
    const { loading } = this.props;
    return (
        <div style={{ height: innerHeight + 'px'}} >
        <Image src={logo} style={{position:'absolute',right:'10px',bottom:'10px'}} />
            <Grid textAlign='center'
                  style={{ height: 'inherit' }}
                  verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                   <LoginForm login={this.submitLogin} loading={loading} />
                </Grid.Column>
            </Grid>
        </div>
    )
  }
}


Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  history: PropTypes.object
};

function mapStateToProps(state) {
  return { 
      loading: state.login.loading, 
      error: state.login.error
    };
}

export default connect(mapStateToProps)(Login);