
import ApiCall, { constructQueryUrl } from '../../../api/ApiCall';
import configuration from '../../../configuration';
import { showToast } from '../../../toast/actions/toast';
import resources from '../../../resources';

export const SHOW_SETTING_FORM = 'SHOW_SETTING_FORM';
export const HIDE_SETTING_FORM = 'HIDE_SETTING_FORM';

export const FETCH_SETTINGS_STARTED = 'FETCH_SETTINGS_STARTED';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILED = 'FETCH_SETTINGS_FAILED';

export const CREATE_SETTING_STARTED = 'CREATE_SETTING_STARTED';
export const CREATE_SETTING_SUCCESS = 'CREATE_SETTING_SUCCESS';
export const CREATE_SETTING_FAILED = 'CREATE_SETTING_FAILED';

export const SETTING_URL = configuration.ROOT_URL + '/api/v1/setting';

/**
 * Set showform=true, ui should show form based on this
 */
export function showSettingForm(){
    return { type: SHOW_SETTING_FORM }
}
/**
 * Set showform=false, ui should hide form based on this
 */
export function hideSettingForm(){
    return { type: HIDE_SETTING_FORM }
}

function fetchSettingsStarted() {
    return { type: FETCH_SETTINGS_STARTED }
}
function fetchSettingsSucceeded(settings) {
    return { type: FETCH_SETTINGS_SUCCESS, payload:  {settings}  }
}
function fetchSettingsFailed(error) {
    return { type: FETCH_SETTINGS_FAILED, payload: {error} }
}
/**
 * Get settings with queryparameters
 * @param {*} query 
 */
export function fetchSettings(query) {
    
	return async dispatch => { 
        try { 
            dispatch(fetchSettingsStarted());
            const data = await new ApiCall().get(constructQueryUrl(SETTING_URL, query));
            dispatch(fetchSettingsSucceeded(data));
        } catch (e) {
            dispatch(fetchSettingsFailed(e.message));
            dispatch(showToast(resources.FAILED, e.message,'red'));
        }
	}
}

function createSettingStarted() {
    return { type: CREATE_SETTING_STARTED  }
}
function createSettingSucceeded(settings) {
    return { type: CREATE_SETTING_SUCCESS, payload:{settings}  }
}
function createSettingFailed(error) {
    return { type: CREATE_SETTING_FAILED, payload: {error} }
}
/**
 * Create setting
 * @param {*} settings : object 
 */
export function createSetting(settings) {
   
	return async dispatch => { 
        try { 
            dispatch(createSettingStarted())
            const data = await new ApiCall().create(SETTING_URL, settings);
            dispatch(createSettingSucceeded(data));
            dispatch(showToast(resources.SAVED, '','green'));
            dispatch(hideSettingForm());
        } catch (e) {
            let msg = e.message;
            dispatch(createSettingFailed(msg));
            dispatch(showToast(resources.FAILED, msg,'red'));
        }
	}
}




