import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OrderManagement from './containers/OrderManagement';
import OrderHeaderManagement from './containers/OrderHeaderManagement';
import { getOrderHeader } from './reducers/orderheader';

class Orders extends Component {
  
  render() {
    const { edititem } = this.props;
   
    return (
      <React.Fragment>
        {edititem ? (<OrderManagement />) : <OrderHeaderManagement />}
      </React.Fragment>
    );
  }
}

Orders.propTypes = {
    edititem: PropTypes.object
};

function mapStateToProps(state) {
    let edititem = getOrderHeader(state.orderheader.selectedid, state.orderheader.orderheaders);
    return { 
        edititem:edititem,
        selectedid:state.orderheader.selectedid
    };
}

export default connect(mapStateToProps)(Orders);