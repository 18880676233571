import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseTable from '../../../components/BaseTable';
import resources from '../../../resources';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import moment from 'moment';
import { loglevels } from '../common/LogLevels';

class LogTable extends Component { 
    constructHeaders (){
        
        return [

            { value: resources.CREATED, name: 'Created' },
            { value: resources.TITLE, name: 'Title' },
            { value: resources.MESSAGE, name: 'Message' },
            { value: resources.LOGLEVEL, name: 'LogLevel' },
            { value: resources.USER, name: 'ApplicationUserId' },
            { value: resources.CLIENT_ID, name: 'ClientId' },
            { value: resources.EVENT, name: 'EventId' },
            { value: resources.SOURCE, name: 'Source' },
            { value: '', style:{width:'10px'} }
        ];
    };
    cutLength(text, maxLength){
        if(text === undefined || text === null)
            return text;

        let cutText = '' + text;
        if(cutText.length > maxLength)
            cutText = cutText.substring(0,maxLength) + '...';

        return cutText;
    }
    
    constructRows(data){
       const { getbyid } = this.props;
        return data.map((item, index) => {

          
            return {
                cells: [{
                            value: moment(item.Created).format('DD.MM.YYYY HH:mm:ss:Z')
                        },{
                            value: this.cutLength(item.Title,40)
                        },{
                            value: this.cutLength(item.Message,40)
                        }, {
                            value: this.cutLength(item.LogLevel + ' ' + (item.LogLevel === loglevels.Info ? resources.INFO :
                                                                        (item.LogLevel === loglevels.Warning ? resources.WARNING :
                                                                        (item.LogLevel === loglevels.Error ? resources.ERROR : ''))),10)
                        }, {
                            value: this.cutLength(item.ApplicationUserId === '00000000-0000-0000-0000-000000000000' ? '' : item.ApplicationUserId,20)
                        }, {
                            value: this.cutLength(item.ClientId,40)
                        }, {
                            value: this.cutLength(item.EventId,40)
                        }, {
                            value: this.cutLength(item.Source,40)
                        },{
                            custom:<Popup trigger={<Button icon size='mini' onClick={() => getbyid(item.Id)} basic primary>
                                                        <Icon name='edit' />
                                                    </Button>} content={resources.VIEW} />,
                            style:{whiteSpace:'nowrap'}
                        }]
            };
        });
    };
    render() {
        const { loading, data, sortby, pageSize, mobilecolumns, tabletcolumns, loadData } = this.props;
        return (<BaseTable 
            loading={loading}
            headers={this.constructHeaders()}
            rows={this.constructRows(data)}
            sortby={sortby}
            pageSize={pageSize}
            mobilecolumns={mobilecolumns}
            tabletcolumns={tabletcolumns}
            loadEntries={loadData}
        />);
    }
}
LogTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    sortby: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    getbyid: PropTypes.func,
    mobilecolumns: PropTypes.number, //not required
    tabletcolumns: PropTypes.number //not required
}
  
export default LogTable;