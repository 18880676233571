// reducers.js
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import login from './../pages/login/reducers/login';
import toast from './../toast/reducers/toast';
import logs from '../pages/logview/reducers/logs';
import orderrow from './../pages/orders/reducers/orderrow';
import orderheader from './../pages/orders/reducers/orderheader';
import setting from './../pages/orders/reducers/setting';


export default (history) => combineReducers({
  router: connectRouter(history),
  login,
  orderrow,
  orderheader,
  toast,
  logs,
  setting
});
  