import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import Routes from './routes/routes';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/fi';
import moment from 'moment';
import history from './common/history';
moment.locale('fi'); //Set default locale

const store = configureStore();
ReactDOM.render(<Routes store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
