import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LoginCheck from '../pages/login/containers/LoginCheck';
import ToastMessage from "../toast/components/ToastMessage";
import UserRoutes from './containers/UserRoutes';
import MenuSwitcher from '../components/MenuSwitcher';


class Routes extends Component {
    
  render(){
       const { store, history } = this.props;
        return (
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <React.Fragment>
                            <LoginCheck />
                            <ToastMessage history={history} />
                            <MenuSwitcher history={history} />
                            <UserRoutes />
                        </React.Fragment>
                    </ConnectedRouter>
                </Provider>
                
        );

    }
}
  
Routes.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default Routes;