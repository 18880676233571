import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import resources from '../../../resources';
import OrderRow from '../models/OrderRow';
import { Dropdown } from 'semantic-ui-react';
import { getOrderStateDropdownOptions } from '../common/OrderState';
import moment from 'moment'

class OrderRowForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderrow: { ...new OrderRow(), createdBy:this.props.user ? this.props.user : '', orderHeaderId:this.props.headerId}
        };

    }
    
    componentDidUpdate(prevProps, prevState) {

        //If editing, or editing is changed, then update                          
        if( JSON.stringify(prevProps.edititem) !== JSON.stringify(this.props.edititem) && this.props.edititem !== null && this.props.edititem !== undefined ){
            this.setState({orderrow:{...this.props.edititem, modifiedBy:this.props.user ? this.props.user : '', orderHeaderId:this.props.headerId}});
        }
        //If show is changed
        else if(prevProps.show !== this.props.show) {

            if(this.props.show === false)
                this.setState({ orderrow: { ...new OrderRow(), createdBy:this.props.user ? this.props.user : '', orderHeaderId:this.props.headerId} });
        }

    }
 
    close = (e) => {
        if(e) e.preventDefault();
        const { hideform } = this.props;
        this.setState({ orderrow: { ...new OrderRow(), createdBy:this.props.user ? this.props.user : '', orderHeaderId:this.props.headerId} }, () => hideform());
    }
    onchange = (key, value) => {
        this.setState((prevState, props) => {
            return {
                orderrow: { ...prevState.orderrow, ...{ [key]: value } }
            };
        });
    }
    save = () => {
        const { orderrow } = this.state;
        const { create, update } = this.props;


        if (orderrow.id !== 0) {
            orderrow.modified = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            update(orderrow.id, {...orderrow});
        }
        else {
            orderrow.created = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
            create(orderrow);
        }
    }
    render() {
        
        const {  text, size, loading, show } = this.props;
        let { orderrow } = this.state;
        let isNew = orderrow.id === 0;

        return (
            <React.Fragment>
     
            <Button icon size={size ? size : 'mini'}  style={{whiteSpace:'nowrap',minHeight:'2.9em'}} onClick={() => this.props.showform()} basic>
                <Icon name='add circle' />&nbsp;
                {text !== undefined ? text : resources.CREATE_NEW}
            </Button>

            <Modal size='small' open={show} onClose={this.close} closeIcon closeOnDimmerClick={false}>
                <Modal.Header>
                {isNew ? resources.CREATE_NEW : resources.EDIT}
                </Modal.Header>
                <Modal.Content style={{ padding: '0' }}>
                
                    <Form onSubmit={(e) => { e.preventDefault(); this.save(); }}>
                        <Segment basic>
                            <Dimmer active={loading} inverted>
                                <Loader />
                            </Dimmer>

                                <Form.Field required>
                                    <label>{resources.ORDERLIST_NUMBER}</label>
                                    <input placeholder={resources.ORDERLIST_NUMBER} required readOnly style={{color:'gray',background:'#ececec'}}
                                        type='text' value={orderrow.id === null ? '' : orderrow.id}
                                        onChange={(e) => this.onchange('id', e.target.value)} />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{resources.PRODUCT_CODE}</label>
                                    <input placeholder={resources.PRODUCT_CODE} required
                                        type='text' value={orderrow.productCode == null ? '' : orderrow.productCode}
                                        onChange={(e) => this.onchange('productCode', e.target.value)} />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{resources.NAME}</label>
                                    <input placeholder={resources.NAME} required
                                        type='text' value={orderrow.productName == null ? '' : orderrow.productName}
                                        onChange={(e) => this.onchange('productName', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.PRODUCT_EXTRA}</label>
                                    <input placeholder={resources.PRODUCT_EXTRA} 
                                        type='text' value={orderrow.productNameExtra == null ? '' : orderrow.productNameExtra}
                                        onChange={(e) => this.onchange('productNameExtra', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.VENDOR}</label>
                                    <input placeholder={resources.VENDOR}
                                        type='text' value={orderrow.vendor}
                                        onChange={(e) => this.onchange('vendor', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.VENDOR_PRODUCT_CODE}</label>
                                    <input placeholder={resources.VENDOR_PRODUCT_CODE}
                                        type='text' value={orderrow.vendorProductCode === null ? '' : orderrow.vendorProductCode}
                                        onChange={(e) => this.onchange('vendorProductCode', e.target.value)} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.CATEGORY}</label>
                                    <input placeholder={resources.CATEGORY} 
                                        type='text' value={orderrow.category === null ? 0 : orderrow.category}
                                        onChange={(e) => this.onchange('category', e.target.value)} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.SHELF_LOCATION}</label>
                                    <input placeholder={resources.SHELF_LOCATION}
                                        type='text' value={orderrow.place === null ? '' : orderrow.place}
                                        onChange={(e) => this.onchange('place', e.target.value)} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.SALESUNIT}</label>
                                    <input placeholder={resources.SALESUNIT}
                                        type='text' value={orderrow.salesUnit === null ? '' : orderrow.salesUnit}
                                        onChange={(e) => this.onchange('salesUnit', e.target.value)} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.STORAGE_QUANTITY}</label>
                                    <input placeholder={resources.STORAGE_QUANTITY}
                                        type='number' value={orderrow.storageQuantity === null ? '' : orderrow.storageQuantity}
                                        onChange={(e) => this.onchange('storageQuantity', window.parseFloat(e.target.value))} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.ORDER_QUANTITY}</label>
                                    <input placeholder={resources.ORDER_QUANTITY}
                                        type='number' value={orderrow.orderQuantity === null ? '' : orderrow.orderQuantity}
                                        onChange={(e) => this.onchange('orderQuantity', window.parseFloat(e.target.value))} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.SALESPRICE}</label>
                                    <input placeholder={resources.SALESPRICE}
                                        type='number' value={orderrow.salesPrice === null ? '' : orderrow.salesPrice}
                                        onChange={(e) => this.onchange('salesPrice', window.parseFloat(e.target.value))} />
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>{resources.STORAGEPRICE}</label>
                                    <input placeholder={resources.STORAGEPRICE}
                                        type='number' value={orderrow.storagePrice === null ? '' : orderrow.storagePrice}
                                        onChange={(e) => this.onchange('storagePrice', window.parseFloat(e.target.value))} />
                                </Form.Field>

                                <Form.Field>
                                    <label>{resources.STORAGEUNIT}</label>
                                    <input placeholder={resources.STORAGEUNIT}
                                        type='text' value={orderrow.storageUnit === null ? '' : orderrow.storageUnit}
                                        onChange={(e) => this.onchange('storageUnit', e.target.value)} />
                                </Form.Field>
                                
                                
                                <Form.Field>
                                    <label>{resources.STATE}</label>
                                    <Dropdown placeholder={resources.STATE}
                                                fluid value={orderrow.orderStatus}
                                                selection onChange={(e, { value }) => this.onchange('orderStatus', value)}
                                                options={getOrderStateDropdownOptions()} />
                                </Form.Field>

                        </Segment>
                        <Segment attached='bottom' secondary style={{ margin: '0', width: '100%' }}>
                            <Button positive icon='save' type='submit' labelPosition='left' content={resources.SAVE} disabled={loading} />
                            <Button onClick={this.close}>{resources.CANCEL}</Button>
                        </Segment>
                    </Form>

                </Modal.Content>

            </Modal> 
        </React.Fragment>
        );
    }
}
OrderRowForm.propTypes = {
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    edititem: PropTypes.object,
    text: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    showform: PropTypes.func.isRequired,
    hideform: PropTypes.func.isRequired,
    show: PropTypes.bool,
    clear: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    user: PropTypes.string,
    headerId: PropTypes.number
}
  
export default OrderRowForm;