import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseTable from '../../../components/BaseTable';
import resources from '../../../resources';
import moment from 'moment';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import  Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import { getOrderStateText, OrderState } from '../common/OrderState';

class OrderRowTable extends Component { 
    
    
    render() {
        const { loading, data, sortby, pageSize, mobilecolumns, tabletcolumns, loadData, getbyid, remove, hidePaging,editable } = this.props;
        return (<BaseTable className='data-class'
            loading={loading}
            headers={constructOrderRowTableHeaders(editable)}
            rows={constructOrderRowTableRows(data, getbyid, remove,editable)}
            sortby={sortby}
            pageSize={pageSize}
            mobilecolumns={mobilecolumns}
            tabletcolumns={tabletcolumns}
            loadEntries={loadData}
            hidePaging={hidePaging}
        />);
    }
}
OrderRowTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    sortby: PropTypes.func.isRequired,
    loadData: PropTypes.func,
    pageSize: PropTypes.number.isRequired,
    mobilecolumns: PropTypes.number, //not required
    tabletcolumns: PropTypes.number, //not required
    getbyid: PropTypes.func,
    remove: PropTypes.func,
    hidePaging: PropTypes.bool,
    editable: PropTypes.bool
}

export default OrderRowTable;


/*
{
        {
            "id": 0,
            "orderHeaderId": 0,
            "category": "string",
            "place": "string",
            "productCode": "string",
            "productName": "string",
            "storageQuantity": 0,
            "salesUnit": "string",
            "storageUnit": "string",
            "created": "2019-10-31T08:56:42.777Z",
            "modified": "2019-10-31T08:56:42.777Z",
            "createdBy": "string",
            "modifiedBy": "string",
            "orderStatus": "None"
            "vendor": "string"
            "vendorProductCode": "string"
            }
        }
*/

export const constructOrderRowTableHeaders = (editable) => {
    let headers = [
        { value: resources.PRODUCT_CODE, name: 'productCode' },
        { value: resources.NAME, name: 'productName' },
        { value: resources.PRODUCT_EXTRA, name: 'productNameExtra', style:{display:'none'} },
        { value: resources.VENDOR, name: 'vendor' },
        { value: resources.VENDOR_PRODUCT_CODE, name: 'vendorProductCode', style:{display:'none'} },
        { value: resources.CATEGORY, name: 'category' },
        { value: resources.SHELF_LOCATION, name: 'place' },
        { value: resources.SALESUNIT, custom:<React.Fragment>{resources.SALESUNIT_SHORT}<br/>{resources.UNIT}</React.Fragment>, name: 'salesUnit' },
        { value: resources.STORAGEUNIT, custom:<React.Fragment>{resources.STORAGEUNIT_SHORT}<br/>{resources.UNIT}</React.Fragment>, name: 'storageUnit' },
        { value: resources.CREATED, name: 'created' },
        { value: resources.CREATED_BY, name: 'createdBy', style:{display:'none'} },
        { value: resources.UPDATED, name: 'modified' },
        { value: resources.MODIFIED_BY, name: 'modifiedBy', style:{display:'none'}  },
        { value: resources.STORAGE_QUANTITY, name: 'storageQuantity' },
        { value: resources.ORDER_QUANTITY, name: 'orderQuantity' },
        { value: resources.SALESPRICE, name: 'salesPrice' },
        { value: resources.STORAGEPRICE, name: 'storagePrice' },
        { value: resources.PRICE, name:'price' },
        { value: resources.STATE, name: 'orderStatus' }
    ];
    if(editable === true)
        headers.push({ value: '', width: 1, name: '', style:{width:'55px'} });

    return headers;
}


export const constructOrderRowTableRows = (data, getbyid, remove, editable) => {
 
    return data.map((item, index) => {

        let row = [{
            value:item.productCode,
        },{
            value:item.productName
        },{
            value:item.productNameExtra, style:{display:'none'}
        },{
            value:<React.Fragment>{item.vendor && item.vendor !== '' && <div>{item.vendor}</div>}{item.vendorProductCode}</React.Fragment>,
            csv:item.vendor
        },{
            value:item.vendorProductCode, style:{display:'none'}
        },{
            value:item.category
        },{
            value:item.place
        },{
            value:item.salesUnit
        },{
            value:item.storageUnit
        },{
            custom:<React.Fragment>{item.created ? moment(item.created).local().format('DD.MM.YYYY HH:mm:ss') : ''}<br />{item.createdBy}</React.Fragment>,
            csv:item.created ? moment(item.created).local().format('DD.MM.YYYY HH:mm:ss') : ''
        },{
            value:item.createdBy, style:{display:'none'}
        },{
            custom:<React.Fragment>{item.modified ? moment(item.modified).local().format('DD.MM.YYYY HH:mm:ss') : ''}<br />{item.modifiedBy}</React.Fragment>,
            csv:item.modified ? moment(item.modified).local().format('DD.MM.YYYY HH:mm:ss') : '',
        },{
            value:item.modifiedBy, style:{display:'none'}
        },{
            value:item.storageQuantity ? item.storageQuantity.toFixed(2).toString().replace(".",",") : "0,00"
        },{
            value:item.orderQuantity ? item.orderQuantity.toFixed(2).toString().replace(".",",") : "0,00"
        },{
            value:item.salesPrice ? item.salesPrice.toFixed(2).toString().replace(".",",") : "0,00"
        },{
            value:item.storagePrice ? item.storagePrice.toFixed(2).toString().replace(".",",") : "0,00"
        },{
            value:(item.salesPrice && item.orderQuantity) ? (item.salesPrice * item.orderQuantity).toFixed(2).toString().replace(".",",") : "0,00"
        },{
            value:getOrderStateText(item.orderStatus),
            //warning:item.orderStatus == OrderState.,
            negative:item.orderStatus === OrderState.Rejected,
            warning:item.orderStatus === OrderState.InProgress,
            positive:item.orderStatus === OrderState.Accepted
        }];

        if(editable === true)
            row.push({
                custom:<React.Fragment><Popup trigger={<Button icon size='mini' onClick={() => getbyid(item.id)} basic primary>
                                                <Icon name='edit' />
                                            </Button>} content={resources.EDIT} /><Button icon size='mini' onClick={() => remove(item.id)} basic color='red'>
                                                <Icon name='remove' />
                                            </Button></React.Fragment>,
                style:{whiteSpace:'nowrap'}
            });
        
        return {
            cells: row
        };
    });
};